import { combineReducers } from 'redux';
import interactions from './interactions';
import training from './training';
import templateTraining from './templateTraining';
import user from './user';
import app from './app';

const rootReducer = combineReducers({
  interactions,
  training,
  templateTraining,
  user,
  app,
});

export default rootReducer;
