import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  DRAWER_WIDTH, INTERACTIONS_MODE, TRAINING_MODE, TEMPLATE_TRAINING_MODE,
} from '../utils/constants'
import { setSidebarMode } from '../actions/app';
import { autobind } from '../utils/objectUtils';
import InteractionsSidebar from './InteractionsSidebar';
import TrainingSidebar from './TrainingSidebar';
import TemplateTrainingSidebar from './TemplateTrainingSidebar';

function mapStateToProps(state) {
  return {
    sidebarMode: state.app.sidebarMode,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setSidebarMode: (mode) => dispatch(setSidebarMode(mode)),
  };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

class Sidebar extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    sidebarMode: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    autobind(this);
  }

  handleModeChange(e, sidebarMode) {
    this.props.actions.setSidebarMode(sidebarMode);
  }

  render() {
    const {
      classes,
      sidebarMode,
    } = this.props
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !this.props.isOpen && classes.drawerPaperClose),
        }}
        open={this.props.isOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={this.props.onClose}>
            <ChevronRightIcon />
          </IconButton>
          <Tabs value={sidebarMode} onChange={this.handleModeChange}>
            <Tab value={INTERACTIONS_MODE} label="Interactions" />
            <Tab value={TRAINING_MODE} label="Fields Training" />
            <Tab value={TEMPLATE_TRAINING_MODE} label="Template Training" />
          </Tabs>
        </div>
        <Divider />
        <div style={{ position: 'relative', height: '100%' }}>
          {sidebarMode === INTERACTIONS_MODE
            ? <InteractionsSidebar />
            : sidebarMode === TRAINING_MODE
              ? <TrainingSidebar />
              : <TemplateTrainingSidebar />}
        </div>
      </Drawer>
    );
  }
}

const componentWithStyles = withStyles(styles)(Sidebar);

const container = connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actions: dispatchProps,
}))(componentWithStyles)

export default hot(module)(container);
