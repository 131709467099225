import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { autobind } from '../../utils/objectUtils';
import { FIELD_SETTERS } from '../../actions/templateTraining';

function mapStateToProps(state) {
  return {
    validRegex: state.templateTraining.validRegex,
    typeToTrain: state.templateTraining.typeToTrain,
    selector: state.templateTraining.selector,
    regex: state.templateTraining.regex,
    regexReplace: state.templateTraining.regexReplace,
  };
}

function mapDispatchToProps(dispatch) {
  const debouncedDispatch = debounce(dispatch, 300);
  return {
    setSelector: (selector) => dispatch(FIELD_SETTERS.setSelector(selector)),
    setRegex: (regex) => dispatch(FIELD_SETTERS.setRegex(regex)),
    setRegexReplace: (regexReplace) => dispatch(FIELD_SETTERS.setRegexReplace(regexReplace)),
    setValidRegex: (validRegex) => debouncedDispatch(FIELD_SETTERS.setValidRegex(validRegex)),
  }
}

class FieldForm extends React.Component {
  static propTypes = {
    setSelector: PropTypes.func.isRequired,
    setRegex: PropTypes.func.isRequired,
    setRegexReplace: PropTypes.func.isRequired,
    setValidRegex: PropTypes.func.isRequired,
    validRegex: PropTypes.bool,
    theme: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    typeToTrain: PropTypes.string.isRequired,
    selector: PropTypes.shape({
      type: PropTypes.string.isRequired,
      selector: PropTypes.string.isRequired,
    }).isRequired,
    regex: PropTypes.string.isRequired,
    regexReplace: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      hasRegex: false,
      value: `new ${props.typeToTrain}({ selector: { ${props.selector.type}: "${props.selector.selector}" } });`,
    }
    autobind(this);
  }

  shouldComponentUpdate(prevProps, prevState) {
    const { selector, type } = this.props.selector;
    const { value, hasRegex } = this.state;
    const {
      regex,
      validRegex,
      regexReplace,
      typeToTrain,
    } = this.props;
    if (
      value !== prevState.value
      || regex !== prevProps.regex
      || validRegex !== prevProps.validRegex
      || regexReplace !== prevProps.regexReplace
      || hasRegex !== prevState.hasRegex
      || selector !== prevProps.selector.selector
      || type !== prevProps.selector.type
      || typeToTrain !== prevProps.typeToTrain
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    this.setState({
      hasRegex: !!this.props.regex,
      value: this.getValue(),
    });
  }

  getValue() {
    const {
      validRegex, regex, regexReplace, typeToTrain,
    } = this.props;
    const { type, selector } = this.props.selector;
    const regexString = validRegex && !!regex ? `, regExp: "${regex}"` : '';
    const regexReplaceString = !!regexString && !!regexReplace ? `, regExpReplace: "${regexReplace}"` : '';
    return `new ${typeToTrain}({ selector: { ${type}: "${selector}"${regexString}${regexReplaceString} } });`;
  }

  onSelectorChange(e) {
    const { value } = e.target;
    this.props.setSelector({
      type: this.props.selector.type,
      selector: value,
    });
  }

  onRegexChange(e) {
    const { value } = e.target;
    let validRegex = true;
    let hasRegex = false;
    if (value !== '') {
      hasRegex = true;
      try {
        new RegExp(value);
      } catch {
        validRegex = false;
      }
    }
    this.setState({
      hasRegex,
    });
    this.props.setRegex(value);
    if (this.props.validRegex !== validRegex) {
      this.props.setValidRegex(validRegex);
    }
  }

  onRegexReplaceChange(e) {
    const { value } = e.target;
    this.props.setRegexReplace(value);
  }

  render() {
    const { selector } = this.props.selector;
    const { hasRegex } = this.state;
    const { regex, validRegex, regexReplace } = this.props;
    const editorHeight = hasRegex ? '65%' : '75%';
    const overallHeight = hasRegex ? '400px' : '300px';
    return (
      <div style={{ height: overallHeight, width: 'calc(100% - 20px)' }}>
        <form>
          <FormControl style={{ width: '100%' }}>
            <TextField
              id="css-selector-field"
              label="CSS Selector"
              style={{ margin: 10 }}
              placeholder="Click to select, or type in selector"
              helperText="This is the CSS selector the field will use."
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.onSelectorChange}
              value={selector}
            />
            <TextField
              error={!validRegex}
              id="regex-field"
              label={`${validRegex ? '' : 'Invalid'} Regular Expression`}
              style={{ margin: 10 }}
              placeholder="Regular Expression (optional)"
              helperText="Regular Expression to apply to element's value"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.onRegexChange}
              value={regex}
            />
            {hasRegex
              && (
              <TextField
                id="regex-replace-field"
                label="Regex Replace"
                style={{ margin: 10 }}
                placeholder="Replacement Text (optional)"
                helperText="Text matching the above regular expression will be replaced with this text"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.onRegexReplaceChange}
                value={regexReplace}
              />
              )}
          </FormControl>
        </form>
        <AceEditor
          style={{ maxHeight: 100, height: editorHeight, width: 'calc(100% + 10px)' }}
          mode="javascript"
          theme={this.props.theme}
          name="templatedField"
          key="templatedField"
          editorProps={{ $blockScrolling: Infinity }}
          fontSize={this.props.fontSize}
          showGutter={false}
          highlightActiveLine
          value={this.state.value}
          tabSize={2}
          wrapEnabled
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldForm);
