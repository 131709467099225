import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { TEMPLATE_FIELD_TYPES } from '../../utils/constants';
import { selectFieldType, resetTraining, setSelectorWithResetSaga } from '../../actions/templateTraining';
import FieldForm from './FieldForm';
import FieldFactory from './FieldFactory';
import UserPreferences from '../../utils/userPreferences';
import { autobind } from '../../utils/objectUtils';

function mapStateToProps(state) {
  return {
    typeToTrain: state.templateTraining.typeToTrain,
    selector: state.templateTraining.selector,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetTraining: () => dispatch(resetTraining()),
    selectFieldType: (e) => dispatch(selectFieldType(e.target.value)),
    setSelector: (selector) => dispatch(setSelectorWithResetSaga(selector)),
  };
}

class TemplateTrainingSidebar extends React.PureComponent {
  static propTypes = {
    typeToTrain: PropTypes.string.isRequired,
    selectFieldType: PropTypes.func.isRequired,
    resetTraining: PropTypes.func.isRequired,
    selector: PropTypes.shape({
      type: PropTypes.string.isRequired,
      selector: PropTypes.string.isRequired,
    }).isRequired,
    setSelector: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const preferences = UserPreferences.getPreferences();
    this.fontSize = preferences.editorFontSize;
    this.theme = preferences.editorTheme;
    autobind(this);
  }

  changeSelectorType() {
    const { type, selector } = this.props.selector;
    if (type === 'xpath') {
      this.props.setSelector({
        type: 'css',
        selector,
      });
    } else {
      this.props.setSelector({
        type: 'xpath',
        selector,
      });
    }
  }

  render() {
    const { type } = this.props.selector;
    const nextType = type === 'css' ? 'XPATH' : 'CSS';
    const typeButtonLabel = `Change to ${nextType}`;
    return (
      <div className="template-training-sidebar">
        <div className="training-selection-bar">
          <FormControl
            style={{
              margin: 20,
            }}
          >
            <InputLabel htmlFor="field-type-selector">Field Type</InputLabel>
            <Select
              id="field-type-selector"
              style={{
                minWidth: 200,
              }}
              value={this.props.typeToTrain}
              onChange={this.props.selectFieldType}
            >
              {Object.values(TEMPLATE_FIELD_TYPES).map(
                (type) => <MenuItem key={type} value={type}>{type}</MenuItem>,
              )}
            </Select>
          </FormControl>
          <div className="centered-button">
            <Button
              color="secondary"
              variant="contained"
              onClick={this.changeSelectorType}
            >
              {typeButtonLabel}
            </Button>
          </div>
          <div className="centered-button">
            <Button
              color="secondary"
              variant="contained"
              onClick={this.props.resetTraining}
            >
              Reset Training
            </Button>
          </div>
        </div>
        <Divider />
        <FieldForm
          theme={this.theme}
          fontSize={this.fontSize}
        />
        <Divider />
        <div style={
          {
            maxHeight: '350px !important',
            minHeight: 350,
            height: 350,
            width: 'calc(100%-20px)',
            marginTop: 10,
            marginRight: 10,
            overflow: 'scroll',
          }
        }
        >
          <FieldFactory />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateTrainingSidebar);
