import { endpoints } from '@import/frontend-utils';
import * as actions from '../actions/app';
import { INTERACTIONS_MODE } from '../utils/constants';
import { createSdk } from '../utils/jssdk'

const initialState = {
  cliMode: false,
  sidebarMode: INTERACTIONS_MODE,
  environment: endpoints.rootDomain,
  typescriptProject: false,
  isCliConnected: false,
  isCliConnecting: false,
  chromiumEndpoint: `wss://chromium.${endpoints.rootDomain}/websocket`,
};

export default function (state = initialState, action = { type: 'null' }) {
  switch (action.type) {
    case actions.SET_SIDEBAR_MODE:
      const { sidebarMode } = action;
      return { ...state, sidebarMode };
    case actions.CLI_MODE_DETECTED:
      return {
        ...state,
        cliMode: true,
      }
    case actions.SET_ENVIRONMENT:
      const { environment } = action;
      createSdk({ environment })
      endpoints.endpointsRootDomain = environment;
      const chromiumEndpoint = `wss://chromium.${environment}/websocket`
      return { ...state, environment, chromiumEndpoint };
    case actions.SET_TYPESCRIPT_PROJECT:
      const { typescriptProject } = action;
      return { ...state, typescriptProject };
    case actions.CLI_CONNECTED:
      return { ...state, isCliConnected: true, isCliConnecting: false }
    case actions.CLI_DISCONNECTED:
      return { ...state, isCliConnected: false, isCliConnecting: false }
    case actions.CLI_CONNECTING:
      return { ...state, isCliConnecting: true }
    case actions.SET_CHROMIUM_ENDPOINT:
      return { ...state, chromiumEndpoint: `ws://${action.chromiumEndpoint}/websocket` }
    default:
      return state;
  }
}
