import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AceEditor from 'react-ace'
import UserPreferences from '../../utils/userPreferences'

export default class SnippetView extends Component {
  static propTypes = {
    actions: PropTypes.array.isRequired,
    isResizing: PropTypes.bool.isRequired,
    fullSize: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    const preferences = UserPreferences.getPreferences();
    this.codeEditor = React.createRef();
    this.state = {
      fontSize: preferences.editorFontSize,
      theme: preferences.editorTheme,
      snippets: '',
    };
  }

  componentDidMount() {
    this.setUpState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.actions.length !== this.props.actions.length) {
      this.setUpState()
    }
    // this is used to prevent a bug where the code editor doesn't change size after split pane is resized
    if ((prevProps.isResizing && !this.props.isResizing) || (this.props.fullSize !== prevProps.fullSize)) {
      this.codeEditor.current.editor.resize()
    }
  }

  setUpState() {
    this.setState({
      snippets: this.props.actions.map((a) => {
        if (a.toSnippet) {
          return a.toSnippet();
        }
        return '';
      }).join('\n'),
    })
  }

  render() {
    const { snippets, theme, fontSize } = this.state
    return (
      <AceEditor
        ref={this.codeEditor}
        style={{ height: '100%', width: '100%', cursor: 'pointer' }}
        mode="javascript"
        theme={theme}
        name="snippetView"
        key="snippetView"
        editorProps={{ $blockScrolling: Infinity }}
        fontSize={fontSize}
        readOnly
        showGutter={false}
        highlightActiveLine
        value={(snippets || '').toString()}
      />
    )
  }
}
