import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import { withStyles } from '@material-ui/core/styles';
import { HotKeys } from 'react-hotkeys';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FUNCTION_MODAL_HOT_KEYS } from '../../utils/hotKeys';
import UserPreferences from '../../utils/userPreferences';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbar: {
    height: 35,
    minHeight: 35,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  list: {
    width: 250,
  },
  menuButton: {
    padding: 5,
  },
})

const AVAILABLE_EDITORS = [
  { value: 'monokai', text: 'Monokai' },
  { value: 'tomorrow', text: 'Tomorrow' },
  { value: 'textmate', text: 'Textmate' },
  { value: 'twilight', text: 'Twilight' },
  { value: 'kuroir', text: 'Kuroir' },
  { value: 'solarized_dark', text: 'Solarized Dark' },
  { value: 'solarized_light', text: 'Solarized Light' },
  { value: 'terminal', text: 'Terminal' },
];

class CodeEditor extends Component {
  static propTypes = {
    code: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    closeEditor: PropTypes.func,
    play: PropTypes.func,
    classes: PropTypes.object.isRequired,
    isResizing: PropTypes.bool.isRequired,
    fullSize: PropTypes.bool.isRequired,
    onCodeChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const preferences = UserPreferences.getPreferences();
    this.codeEditor = React.createRef();
    this.state = {
      codeText: '',
      fontSize: preferences.editorFontSize,
      theme: preferences.editorTheme,
      tabSize: preferences.editorTabSize,
      editingSetting: '',
    };
  }

  componentDidMount() {
    this.setUpState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.code !== this.props.code) {
      this.setUpState();
    }
    // this is used to prevent a bug where the code editor doesn't change size after split pane is resized
    if ((prevProps.isResizing && !this.props.isResizing) || (this.props.fullSize !== prevProps.fullSize)) {
      this.codeEditor.current.editor.resize()
    }

    if (prevState.codeText !== this.state.codeText && this.props.onCodeChange) {
      this.props.onCodeChange(this.state.codeText)
    }
  }

  async setUpState() {
    this.setState({
      codeText: this.props.code,
    });
  }

  closeCodeEditor = (code = this.props.code) => {
    this.props.onSave(code);
    if (this.props.closeEditor) this.props.closeEditor();
  }

  saveNewCodeText = (close = false) => {
    const { codeText } = this.state;
    this.props.onSave(codeText);
    if (close) this.closeCodeEditor();
  }

  hotkeysHandlers = () => ({
    save: (e) => {
      e.preventDefault();
      if (!(this.state.codeText === this.props.code)) {
        this.saveNewCodeText();
      }
    },
    close: (e) => {
      e.preventDefault();
      this.closeCodeEditor();
    },
  })

  play = () => {
    this.saveNewCodeText();
    this.props.play();
  }

  setDrawerList = (drawerList) => {
    this.setState({ drawerList })
  }

  onSettingsOpen = (event) => {
    this.setState({ settingsAnchorEl: event.currentTarget })
  }

  onSettingsClose = () => {
    this.setState({ settingsAnchorEl: null })
  }

  openThemeList = () => {
    const { classes } = this.props
    const drawerList = (
      <div className={classes.list}>
        <List>
          {AVAILABLE_EDITORS.map(({ text, value }) => (
            <ListItem button key={text} onClick={() => this.selectTheme(value)}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    )
    this.setState({ drawerList, editingSetting: 'theme', settingsAnchorEl: null })
  }

  openFontSizeList = () => {
    const { classes } = this.props
    const drawerList = (
      <div className={classes.list}>
        <List>
          <ListItem button onClick={() => this.selectFontSize(10)}>
            <ListItemText primary={10} />
          </ListItem>
          <ListItem button onClick={() => this.selectFontSize(12)}>
            <ListItemText primary={12} />
          </ListItem>
          <ListItem button onClick={() => this.selectFontSize(14)}>
            <ListItemText primary={14} />
          </ListItem>
          <ListItem button onClick={() => this.selectFontSize(16)}>
            <ListItemText primary={16} />
          </ListItem>
        </List>
      </div>
    )
    this.setState({ drawerList, editingSetting: 'fontSize', settingsAnchorEl: null })
  }

  openTabSizeList = () => {
    const { classes } = this.props
    const drawerList = (
      <div className={classes.list}>
        <List>
          <List>
            <ListItem button onClick={() => this.selectTabSize(2)}>
              <ListItemText primary={2} />
            </ListItem>
            <ListItem button onClick={() => this.selectTabSize(4)}>
              <ListItemText primary={4} />
            </ListItem>
          </List>
        </List>
      </div>
    )
    this.setState({ drawerList, editingSetting: 'tabSize', settingsAnchorEl: null })
  }

  selectTheme = (theme) => {
    this.setState({ theme })
    UserPreferences.setPreference('editorTheme', theme)
    this.onSettingsClose()
  }

  selectFontSize = (fontSize) => {
    this.setState({ fontSize })
    UserPreferences.setPreference('editorFontSize', fontSize)
    this.onSettingsClose()
  }

  selectTabSize = (tabSize) => {
    this.setState({ tabSize })
    UserPreferences.setPreference('editorTabSize', tabSize)
    this.onSettingsClose()
  }

  render() {
    const { classes } = this.props
    const {
      tabSize, theme, fontSize, settingsAnchorEl, editingSetting,
    } = this.state
    const settingsOpen = Boolean(settingsAnchorEl);
    return (
      <div className={classes.root}>
        <AppBar color="default" position="relative">
          <Toolbar className={classes.toolbar} disableGutters>
            <div>
              <IconButton onClick={this.onSettingsOpen} className={classes.menuButton}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={settingsAnchorEl}
                open={settingsOpen}
                onClose={this.onSettingsClose}
                PaperProps={{ style: { width: 200 } }}
              >
                <MenuItem
                  selected={editingSetting === 'theme'}
                  onClick={this.openThemeList}
                >
                  Theme
                </MenuItem>
                <MenuItem
                  selected={editingSetting === 'fontSize'}
                  onClick={this.openFontSizeList}
                >
                  Font Size
                </MenuItem>
                <MenuItem
                  selected={editingSetting === 'tabSize'}
                  onClick={this.openTabSizeList}
                >
                  Tab Size
                </MenuItem>
              </Menu>
            </div>
            <Button className={classes.button} onClick={() => this.saveNewCodeText()}>Apply</Button>
          </Toolbar>
        </AppBar>

        <HotKeys
          keyMap={FUNCTION_MODAL_HOT_KEYS}
          style={{ width: '100%', height: '100%' }}
          handlers={this.hotkeysHandlers()}
        >
          <AceEditor
            ref={this.codeEditor}
            style={{ height: '100%', width: '100%', cursor: 'pointer' }}
            mode="javascript"
            theme={theme}
            name="interactionEditor"
            key="interactionEditor"
            showLineNumbers
            onChange={(codeText) => this.setState({ codeText })}
            editorProps={{ $blockScrolling: Infinity }}
            fontSize={fontSize}
            highlightActiveLine
            value={(this.state.codeText || '').toString()}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              tabSize,
              useSoftTabs: true,
              useWorker: false,
            }}
          />
        </HotKeys>
        <Drawer anchor="right" open={!!this.state.drawerList} onClose={() => this.setDrawerList(null)}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => this.setDrawerList(null)}
            onKeyDown={() => this.setDrawerList(null)}
          >
            {this.state.drawerList}
          </div>
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(CodeEditor);
