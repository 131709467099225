export const FUNCTION_MODAL_HOT_KEYS = {
  save: `${commandOrControl()}+s`,
  close: 'escape',
};

function commandOrControl() {
  try {
    if (navigator && navigator.platform && (typeof navigator.platform) === 'string' && navigator.platform.includes('Mac')) {
      return 'command';
    }
    return 'ctrl';
  } catch (e) {
    return 'ctrl';
  }
}
