import { setApiKey, getCurrentUser } from './user'

export const CLI_MODE_DETECTED = 'CLI_MODE_DETECTED'
export const SET_SIDEBAR_MODE = 'SET_SIDEBAR_MODE';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_TYPESCRIPT_PROJECT = 'SET_TYPESCRIPT_PROJECT';
export const CLI_CONNECTED = 'CLI_CONNECTED';
export const CLI_DISCONNECTED = 'CLI_DISCONNECTED';
export const CLI_CONNECTING = 'CLI_CONNECTING';
export const SET_CHROMIUM_ENDPOINT = 'SET_CHROMIUM_ENDPOINT';

export const setTypeScriptProject = (typescriptProject = true) => ({ type: SET_TYPESCRIPT_PROJECT, typescriptProject })
export const cliConnected = () => ({ type: CLI_CONNECTED })
export const cliDisconnected = () => ({ type: CLI_DISCONNECTED })
export const cliConnecting = () => ({ type: CLI_CONNECTING })
export const cliModeDetected = () => ({ type: CLI_MODE_DETECTED })
export const setChromiumEndpoint = (chromiumEndpoint) => ({ type: SET_CHROMIUM_ENDPOINT, chromiumEndpoint })

export function setSidebarMode(sidebarMode) {
  return {
    type: SET_SIDEBAR_MODE,
    sidebarMode,
  };
}

export function setEnvironment(environment) {
  return {
    type: SET_ENVIRONMENT,
    environment,
  };
}

export function userEnvironmentOverride({ apiKey, environment }) {
  return (dispatch) => {
    dispatch(setEnvironment(environment))
    dispatch(setApiKey(apiKey))
    dispatch(getCurrentUser())
  }
}
