import React from 'react';
import PropTypes from 'prop-types';
import NotesIcon from '@material-ui/icons/Notes';
import MemoryIcon from '@material-ui/icons/Memory';
import TableChartIcon from '@material-ui/icons/TableChart';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import SplitPane from 'react-split-pane';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { autobind } from '../utils/objectUtils';
import { DEFAULT_HORIZONTAL_SIZE } from '../utils/constants'
import * as Interactions from '../actions/interactions'
import UserPreferences from '../utils/userPreferences';
import InteractionSection from './InteractionSection';
import JsonView from './DebuggingTools/JsonView';
import LogView from './DebuggingTools/LogView';

function mapStateToProps(state) {
  return {
    websiteConsole: state.interactions.websiteConsole,
    memory: state.interactions.memory,
    data: state.interactions.data,
    selectedView: state.interactions.selectedView,
    compilingAuthExtractor: state.interactions.compilingAuthExtractor,
    compilingExtractor: state.interactions.compilingExtractor,
    typescriptProject: state.app.typescriptProject,
    isCliConnecting: state.app.isCliConnecting,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Interactions, dispatch),
  };
}

const styles = (theme) => ({
  bottomNavigation: {
    width: '100%',
  },
  toolbar: {
    height: 35,
    minHeight: 35,
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.grey['400'],
  },
})

class InteractionSidebar extends React.PureComponent {
  static propTypes = {
    websiteConsole: PropTypes.array.isRequired,
    data: PropTypes.any.isRequired,
    memory: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      updateWebsiteConsole: PropTypes.func.isRequired,
    }),
    typescriptProject: PropTypes.bool.isRequired,
    compilingExtractor: PropTypes.bool.isRequired,
    compilingAuthExtractor: PropTypes.bool.isRequired,
    selectedView: PropTypes.string.isRequired,
    isCliConnecting: PropTypes.bool.isRequired,
  }

  state = {
    debuggingToolsOpen: true,
    areInteractionsVisible: true,
    hideVisibleActions: false,
    isResizing: false,
    height: UserPreferences.getPreferences().horizontalHeight,
    selectedTab: 0,
  }

  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    if ((this.props.selectedView === 'actionList' || this.props.selectedView === 'authInteractions') && this.props.typescriptProject) {
      this.setState({ hideVisibleActions: true })
    }
  }

  componentDidUpdate(prevProps) {
    const hasViewChanged = (prevProps.selectedView !== this.props.selectedView);
    const hasFinishedLoading = (prevProps.compilingAuthExtractor && !this.props.compilingAuthExtractor) || (prevProps.compilingExtractor && !this.props.compilingExtractor);
    if ((hasViewChanged || hasFinishedLoading) && this.props.typescriptProject) {
      if (this.props.selectedView === 'actionList' || this.props.selectedView === 'authInteractions') {
        this.setState({ hideVisibleActions: true })
      } else {
        this.setState({ hideVisibleActions: false })
      }
    }
  }

  toggleDebuggingTools() {
    this.setState({
      debuggingToolsOpen: !this.state.debuggingToolsOpen,
    });
  }

  onToggleInteractions = async () => {
    const areInteractionsVisible = !this.state.areInteractionsVisible
    await this.setState({ areInteractionsVisible })
    UserPreferences.setPreference('interactionsOpen', areInteractionsVisible)
  }

  onHorizontalResize = async (height) => {
    await this.setState({
      height, debuggingToolsOpen: true, areInteractionsVisible: true, hideVisibleActions: false,
    })
    UserPreferences.setPreference('horizontalHeight', height)
    UserPreferences.setPreference('debuggingToolsOpen', true)
  }

  onHorizontalResizeStart = () => {
    this.setState({ isResizing: true });
  }

  onHorizontalResizeEnd = () => {
    this.setState({ isResizing: false });
  }

  handleChange = (event, selectedTab) => {
    this.setState({ selectedTab })
  }

  getHorizontalHeight() {
    const {
      debuggingToolsOpen, areInteractionsVisible, height, hideVisibleActions,
    } = this.state

    if (!debuggingToolsOpen) return window.innerHeight - 106

    if (areInteractionsVisible && !hideVisibleActions) {
      return height
    }
    return 55
  }

  render() {
    const {
      websiteConsole,
      data,
      memory,
      classes,
      compilingExtractor,
      selectedView,
      compilingAuthExtractor,
      isCliConnecting,
    } = this.props;
    const {
      debuggingToolsOpen,
      areInteractionsVisible,
      isResizing,
      height,
      selectedTab,
      hideVisibleActions,
    } = this.state;
    let toolbar;

    if (isCliConnecting || (compilingExtractor && selectedView === 'actionList') || (compilingAuthExtractor && selectedView === 'authInteractions')) {
      return (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
        }}
        >
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      )
    }

    if (selectedTab === 0) {
      toolbar = (
        <AppBar color="default" position="relative">
          <Toolbar className={classes.toolbar} disableGutters>
            <div>
              <IconButton onClick={() => this.props.actions.updateWebsiteConsole([])} className={classes.menuButton}>
                <NotInterestedIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )
    }

    return (
      <SplitPane
        split="horizontal"
        minSize={75}
        defaultSize={DEFAULT_HORIZONTAL_SIZE}
        size={this.getHorizontalHeight()}
        onChange={this.onHorizontalResize}
        onDragStarted={this.onHorizontalResizeStart}
        onDragFinished={this.onHorizontalResizeEnd}
      >
        <InteractionSection
          onToggleInteractions={this.onToggleInteractions}
          areInteractionsVisible={areInteractionsVisible && !hideVisibleActions}
          isResizing={isResizing}
          height={debuggingToolsOpen ? height : window.innerHeight - 95}
          debuggingToolsOpen={debuggingToolsOpen}
          toggleDisabled={!debuggingToolsOpen || hideVisibleActions}
        />
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BottomNavigation
              value={selectedTab}
              onChange={this.handleChange}
              showLabels
              className={classes.bottomNavigation}
            >
              <BottomNavigationAction label="Log" icon={<NotesIcon />} />
              <BottomNavigationAction label="Data" icon={<TableChartIcon />} />
              <BottomNavigationAction label="Memory" icon={<MemoryIcon />} />
            </BottomNavigation>
            <IconButton onClick={this.toggleDebuggingTools} disabled={!areInteractionsVisible}>
              { debuggingToolsOpen ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
            </IconButton>
          </div>
          {toolbar}
          <div style={{
            // Toolbar is only present on logview which is why we have to check for selectedTab === 0 in the styling below
            height: (areInteractionsVisible && !hideVisibleActions)
              ? `calc(100vh - ${height + (selectedTab === 0 ? 150 : 120)}px)`
              : `calc(100vh - ${selectedTab === 0 ? 200 : 170}px)`,
            overflow: 'scroll',
            backgroundColor: '#272722',
            padding: 10,
          }}
          >
            {(() => {
              if (!debuggingToolsOpen) return null
              switch (selectedTab) {
                case 0:
                  return <LogView values={websiteConsole} />
                case 1:
                  return <JsonView value={data} />
                case 2:
                  return <JsonView value={memory} />
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </SplitPane>
    );
  }
}

const componentWithStyles = withStyles(styles)(InteractionSidebar);

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actions: dispatchProps,
}))(componentWithStyles)
