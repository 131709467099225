import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { TemplatedFields } from '@import/frontend-utils';
import { actions } from '@import/replay-browser-events';
import WebExtractorWrapper from '@import/frontend-utils/dist/TemplatedFields/fields/WebExtractorWrapper';
import { setTemplatedFieldObj } from '../../actions/templateTraining';
import LogView from '../DebuggingTools/LogView';

function mapStateToProps(state) {
  const { templateTraining } = state;
  const {
    typeToTrain,
    selector,
    regex,
    regexReplace,
    validRegex,
  } = templateTraining;
  return {
    typeToTrain,
    selector,
    regex,
    regexReplace,
    validRegex,
    websocket: state.interactions.websocket,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTemplatedFieldObj: (templatedFieldObj) => dispatch(setTemplatedFieldObj(templatedFieldObj)),
  }
}

class FieldFactory extends React.Component {
  static propTypes = {
    typeToTrain: PropTypes.string.isRequired,
    selector: PropTypes.shape({
      type: PropTypes.string.isRequired,
      selector: PropTypes.string.isRequired,
    }).isRequired,
    validRegex: PropTypes.bool,
    regex: PropTypes.string,
    regexReplace: PropTypes.string,
    setTemplatedFieldObj: PropTypes.func.isRequired,
    websocket: PropTypes.object,
  }

  state = {
    outputLog: [],
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      typeToTrain,
      regex,
      regexReplace,
      validRegex,
    } = this.props;
    const { outputLog } = this.state;
    const { selector, type } = this.props.selector;
    if (
      typeToTrain !== nextProps.typeToTrain
      || regex !== nextProps.regex
      || regexReplace !== nextProps.regexReplace
      || validRegex !== nextProps.validRegex
      || selector !== nextProps.selector.selector
      || type !== nextProps.selector.type
      || outputLog.length !== nextState.outputLog.length
    ) {
      return true;
    }
    return false;
  }

  async componentDidUpdate(_, prevState) {
    if (this.state.outputLog.length !== prevState.outputLog.length) {
      return;
    }
    let templatedField;
    const {
      selector,
      typeToTrain,
      validRegex,
      regex,
      regexReplace,
      setTemplatedFieldObj,
      websocket,
    } = this.props;
    const argsObj = {};
    argsObj.selector = { [selector.type]: selector.selector };
    if (validRegex && regex) {
      argsObj.regExp = regex;
      if (regexReplace) {
        argsObj.regExpReplace = regexReplace;
      }
    }
    try {
      templatedField = new TemplatedFields[typeToTrain](argsObj);
      const ecField = WebExtractorWrapper.toECField(templatedField.selector, 'data', regex, regexReplace, false, { uuid });
      const extractorConfig = JSON.stringify({
        fields: [ecField],
        singleRecord: false,
        recordXPath: false,
        screenCapture: false,
      });
      const code = `module.exports = async function(input) {
  const extractorConifg = ${extractorConfig};
  await extractorContext.waitForPage();
  const data = await extractorContext.extractData(extractorConifg);
  return extractorContext.return(data);
}`;
      const codeAction = new actions.CodeAction(window.document, code);
      const res = await websocket.play(codeAction);
      const { data } = res;
      if (data.length) {
        const { group } = data[0];
        const outputLog = [...this.state.outputLog];
        outputLog.push(group);
        this.setState({ outputLog });
      }
    } catch (e) {
      console.error(e);
      const outputLog = [...this.state.outputLog];
      templatedField = null;
      outputLog.push(e);
      this.setState({ outputLog });
    } finally {
      setTemplatedFieldObj(templatedField);
    }
  }

  render() {
    const { outputLog } = this.state;
    const values = outputLog.length ? [outputLog[outputLog.length - 1]] : [];
    return (
      <LogView values={values} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldFactory);
