import { createSelector } from 'reselect';

function getFieldsById(state) {
  return (state.training.fieldsById);
}

function getFieldIdForEdit(state) {
  return (state.training.fieldIdForEdit);
}

export const getFieldForEditString = createSelector(
  [getFieldsById, getFieldIdForEdit],
  (fieldsById, id) => {
    const field = fieldsById[id];
    if (field) {
      return JSON.stringify(field, null, 2);
    }
    return '';
  },
)
