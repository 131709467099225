let PREFERENCES_KEY = ''
let DEFAULT_PREFERENCES = {}

const checkForKey = () => {
  if (!PREFERENCES_KEY) throw new Error('You must set a user preferences key')
}

export function setPreferences(preferences = {}) {
  checkForKey()
  preferences = { ...DEFAULT_PREFERENCES, ...preferences }
  localStorage.setItem(PREFERENCES_KEY, JSON.stringify(preferences))
}

export function getPreferences() {
  checkForKey()
  try {
    return JSON.parse(localStorage.getItem(PREFERENCES_KEY)) || DEFAULT_PREFERENCES || {}
  } catch (e) {
    return null
  }
}

export function setPreference(key, value) {
  checkForKey()
  const preferences = getPreferences()
  preferences[key] = value
  setPreferences(preferences)
}

export function getPreference(key) {
  checkForKey()
  const preferences = getPreferences()
  return preferences[key]
}

export function configure(preferencesKey, defaultSettings) {
  if (!preferencesKey || typeof preferencesKey !== 'string') {
    throw new Error('User preferences: You must provide a key for the application')
  }
  PREFERENCES_KEY = preferencesKey

  if (!defaultSettings || typeof defaultSettings !== 'object' || Array.isArray(defaultSettings)) {
    throw new Error('User preferences: You must provide a valid deault settings object in order to configure')
  }

  DEFAULT_PREFERENCES = defaultSettings
  const userPreferences = getPreferences()
  if (!userPreferences || !Object.keys(userPreferences).length) {
    setPreferences(defaultSettings)
  } else {
    setPreferences({ ...defaultSettings, ...userPreferences })
  }
}

export const setKey = (key) => {
  PREFERENCES_KEY = key
}
export const getKey = () => PREFERENCES_KEY

export default {
  getPreferences,
  getPreference,
  getKey,
  setPreferences,
  setKey,
  setPreference,
  configure,
}
