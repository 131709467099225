import {
  LOADING_USER,
  USER_RECEIVED,
  SET_API_KEY,
} from '../actions/user';

const initialState = {
  currentUser: undefined,
  isLoggedIn: undefined,
  subscription: undefined,
  apiKey: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER_RECEIVED:
      return {
        ...state,
        currentUser: action.currentUser,
        isLoggedIn: true,
        isLoadingUser: false,
      };
    case LOADING_USER:
      return {
        ...state,
        isLoadingUser: true,
      };
    case SET_API_KEY:
      return {
        ...state,
        apiKey: action.apiKey,
      };
    default:
      return state;
  }
}
