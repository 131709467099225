import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { autobind } from '../../utils/objectUtils';
import NewFieldEditor from './NewFieldEditor';
import FieldsViewer from './FieldsViewer';
import { newFieldSaga, setIdForEdit } from '../../actions/training';
import UserPreferences from '../../utils/userPreferences';
import EditFieldEditor from './EditFieldEditor';

function mapStateToProps(state) {
  return {
    tempField: state.training.tempField,
    fields: state.training.fields,
    fieldIdForEdit: state.training.fieldIdForEdit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newField: () => dispatch(newFieldSaga()),
    setIdForEdit: (id) => dispatch(setIdForEdit(id)),
  };
}

class TrainingSidebar extends React.PureComponent {
  static propTypes = {
    newField: PropTypes.func.isRequired,
    setIdForEdit: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    fieldIdForEdit: PropTypes.string.isRequired,
  }

  state = {
    fieldsView: true,
    savedNewField: true,
    editView: false,
    saveField: false,
  }

  constructor(props) {
    super(props);
    this.codeEditor = React.createRef();
    const preferences = UserPreferences.getPreferences();
    this.fontSize = preferences.editorFontSize;
    this.theme = preferences.editorTheme;
    this.wiredNewSaveField = () => {};
    this.wiredSaveEditedField = () => {};
    autobind(this);
  }

  newField() {
    this.setState({ fieldsView: false, editView: false });
    // return to the in-progress field rather than wiping it out
    if (this.state.savedNewField) {
      this.props.newField();
      this.setState({ savedNewField: false });
    }
  }

  viewField() {
    this.setState({ fieldsView: true, editView: false });
  }

  editFields() {
    this.setState({ editView: true, fieldsView: false })
  }

  async saveField() {
    if (this.state.editView) {
      const savedEditedField = this.wiredSaveEditedField()
      this.setState({
        fieldsView: savedEditedField,
        editView: !savedEditedField,
      });
      return;
    }
    const savedNewField = this.wiredNewSaveField();
    this.setState({
      fieldsView: true,
      savedNewField,
    });
  }

  wireSaveNewFieldCallback(newFieldView) {
    this.wiredNewSaveField = newFieldView.saveField.bind(newFieldView);
  }

  wireSaveEditedFieldCallback(editFieldView) {
    this.wiredSaveEditedField = editFieldView.saveField.bind(editFieldView);
  }

  selectFieldForEdit(e) {
    const id = e.target.value;
    this.props.setIdForEdit(id);
  }

  render() {
    const {
      fields,
      fieldIdForEdit,
    } = this.props;
    const ids = fields.map((field) => field.id);
    const {
      fieldsView,
      editView,
    } = this.state;
    return (
      <>
        <AppBar color="default" position="relative">
          <Toolbar>
            <Button className="training-button" variant="contained" onClick={() => this.viewField()}>
              View Fields
            </Button>
            { !editView
              && (
              <Button className="training-button" variant="contained" onClick={() => this.newField()}>
                New Field
              </Button>
              )}
            {!fieldsView
              && (
              <Button className="training-button" variant="contained" onClick={() => this.saveField()}>
                Done
              </Button>
              )}
            {!!this.props.fields.length && fieldsView && !editView
              && (
              <Button className="training-button" variant="contained" onClick={() => { this.setState({ fieldsView: false, editView: true }) }}>
                Edit Fields
              </Button>
              )}
            { editView
              && (
              <FormControl style={{ marginLeft: 5 }}>
                <InputLabel htmlFor="field-selector">Select Field</InputLabel>
                <Select
                  style={{
                    marginLeft: 5,
                    minWidth: 150,
                    maxWidth: 200,
                  }}
                  value={fieldIdForEdit}
                  onChange={this.selectFieldForEdit}
                  id="field-selector"
                >
                  {ids.map((id) => (
                    <MenuItem key={id} value={id}>{id}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              )}
          </Toolbar>
        </AppBar>
        {fieldsView
          ? (
            <FieldsViewer
              theme={this.theme}
              fontSize={this.fontSize}
            />
          ) : editView
            ? (
              <EditFieldEditor
                theme={this.theme}
                fontSize={this.fontSize}
                wireSaveEditedFieldCallback={this.wireSaveEditedFieldCallback}
              />
            )
            : (
              <NewFieldEditor
                theme={this.theme}
                fontSize={this.fontSize}
                wireSaveNewFieldCallback={this.wireSaveNewFieldCallback}
              />
            )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainingSidebar);
