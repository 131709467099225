import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  isWhitespace,
  validHref,
  selectNodes,
  getByFullXPath,
  getRecordElements,
  initializeEditorDocument,
  suggestElements,
} from '@import/web-extractor';
import * as constants from './constants';
import { autobind } from '../../utils/objectUtils';

const canTrain = (field) => !(field.xpath || field.microPath);

const maxOverlays = 250;

export default class SiteRenderer extends React.Component {
  static propTypes = {
    showWebsiteViewConfirmModal: PropTypes.func,
    stopLoading: PropTypes.func,
    selectedPage: PropTypes.object,
    hideFrame: PropTypes.bool,
    onLoadCallback: PropTypes.func,
    onCaptureLink: PropTypes.func,
    onClearSelectedExamples: PropTypes.func,
    changeSelectedFieldType: PropTypes.func,
    noField: PropTypes.bool,
    field: PropTypes.shape({
      selector: PropTypes.array,
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      xpath: PropTypes.string,
      microPath: PropTypes.string,
    }),
    refreshingPageId: PropTypes.string,
    removeExample: PropTypes.func,
    elementClickedCallback: PropTypes.func,
    exampleElements: PropTypes.array,
    counterExampleElements: PropTypes.array,
    pageSet: PropTypes.object,
    children: PropTypes.object,
    enabled: PropTypes.bool,
    // move pageset into an incoming prop
  }

  static defaultProps = {
    onLoadCallback: () => undefined, // Defaults to a noop.
    removeExample: () => undefined, // Defaults to a noop.
    onCaptureLink: () => undefined, // Defaults to a noop.
    onClearSelectedExamples: () => undefined, // Defaults to a noop.
    changeSelectedFieldType: () => undefined, // Defaults to a noop.
    elementClickedCallback: () => undefined, // Defaults to a noop.
    singleRecord: true,
  }

  constructor(props) {
    super(props);
    this.overlays = {};
    this.dataElements = [];
    this.suggestionElements = [];
    this.currentHover = null;
    this.state = {
      processingClick: false,
      isFirstClick: true,
      page: false,
    };
    autobind(this);
  }

  componentDidUpdate(previousProps) {
    if (this.doc && !this.state.processingClick) {
      this.clearOverlays();
      this.highlightData();

      if (_get(previousProps, 'field.id') !== _get(this, 'props.field.id')) {
        // ony do this once
        if (!this.scrolledToData) {
          this.scrolledToData = true;
          this.scrollToData();
        }
      }
    }
    if (this.doc && (this.props.enabled !== previousProps.enabled
        || (typeof (previousProps.enabled) === 'undefined' && this.props.enabled))) {
      if (!this.isEnabled() && this.props.enabled !== previousProps.enabled) {
        const elts = Array.from(this.doc.querySelectorAll('html body *'));
        elts.forEach(this.clearCursors);
        this.removeEventHandlers();
      } else if (this.isEnabled() && this.props.enabled !== previousProps.enabled) {
        //
      } else if (this.isEnabled()) {
        const elts = Array.from(this.doc.querySelectorAll('html body *'));
        elts.forEach(this.notAllowedCursor);
      }
    }
  }

  scrollToData() {
    // show the first with a bit of a margin on top
    if (this.dataElements.length) {
      const firstElt = this.getElement(this.dataElements[0]);
      const b = firstElt.getBoundingClientRect();
      const win = firstElt.ownerDocument.defaultView;

      if (win) {
        win.scrollTo(win.scrollX + b.left + 100, win.scrollY + b.top - 100);
      }
    }
  }

  getGlobalElementOffset() {
    // If a page has a body element which is set to be position relative, the
    // overlays will be positioned relative to the body rather than the html element.
    // This will result in the overlays being shifted down from the location which they
    // should be at. Accounting for the body's client rect allows resolves this corner case
    // as well as handles correctly position the overlays when the user scrolls through the page.
    if (!this.doc || !this.body) {
      throw new Error('Unable to set global element offset, doc or doc body do not exist');
    }
    const bodyRect = this.body.getClientRects()[0];
    const bodyStyle = getComputedStyle(this.body);

    if (bodyStyle.position === 'relative' || bodyStyle.position === 'absolute') {
      return {
        top: bodyRect.top,
        left: bodyRect.left,
      };
    }
    const scrollTop = this.doc.documentElement.scrollTop || this.body.scrollTop;
    const scrollLeft = this.doc.documentElement.scrollLeft || this.body.scrollLeft;
    return {
      top: -scrollTop,
      left: -scrollLeft,
    };
  }

  /**
   * Redraw overlays for a specific class
   * @param  {String} clazz
   * @param  {Array<Node|Attr>} items
   */
  redrawOverlays(clazz, items) {
    const overlayWrapper = document.createElement('div');
    overlayWrapper.setAttribute('class', `${clazz}-wrapper`);

    const elOffset = this.getGlobalElementOffset();

    this.removeElementsOfClass(`${clazz}-wrapper`);
    if (this.overlays[clazz]) {
      this.overlays[clazz].forEach((item) => {
        const el = this.getElement(item);
        this.clearCursors(el);
        delete el.__overlays;
      });
    }
    this.overlays[clazz] = items;
    let i = 0;
    items.forEach((item) => {
      i++;
      if (i > maxOverlays) {
        // TODO: show notice that highlighting the first 250 Only
        return;
      }

      if (!this.acceptableExample(item)) {
        return;
      }

      const el = this.getElement(item);
      this.notAllowedCursor(el);
      this.createOverlay(el, clazz, elOffset, overlayWrapper);
    });
    if (this.doc && this.body) {
      this.body.appendChild(overlayWrapper);
    } else {
      throw new Error('Document or document body do not exist');
    }
  }

  getElement(item) {
    if (item.nodeType === 1) {
      return item;
    }
    if (item.nodeType === undefined || item.nodeType === 2) {
      return item.ownerElement;
    }
    return item.parentNode;
  }

  /**
   * Redraw all the overlays, e.g. because the window was resized
   */
  redrawAllOverlays = () => {
    Object.keys(this.overlays).forEach((key) => {
      this.redrawOverlays(key, this.overlays[key]);
    });
  }

  throttledRedraw() {
    this.clearOverlays();
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.redrawAllOverlays();
      }, 250);
    }
  }

  /**
   * Remove all the overlays
   * TODO: Move to the component that handles the iframe?
   */
  clearOverlays = () => {
    this.removeElementsOfClass(constants.OVERLAY_CLASS_NAME);
  }

  /**
   * Event listener: stopPropagation and preventDefault
   * @param {Event} e Event
   */
  stopEventAsCapturing = (e) => {
    if (e.target.tagName !== 'HTML') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  /**
   * Event listener: stopPropagation but keep default
   * @param {Event} e Event
   */
  stopEventAsCapturingKeepDefault = (e) => {
    if (e.target.tagName !== 'HTML') {
      e.stopPropagation();
    }
  };

  /**
   * Sets events handlers for element selection
   * TODO: Move to the component that handles the iframe?
   */
  setEventHandlers() {
    // stop a bunch of events from happening
    this.doc.addEventListener('keydown', this.stopEventAsCapturing, true);
    this.doc.addEventListener('keyup', this.stopEventAsCapturing, true);
    this.doc.addEventListener('mousedown', this.stopEventAsCapturingKeepDefault, true);
    this.doc.addEventListener('mousewheel', this.stopEventAsCapturingKeepDefault, true);
    this.doc.addEventListener('DOMMouseScroll', this.stopEventAsCapturingKeepDefault, true);
    this.doc.addEventListener('mouseup', this.stopEventAsCapturing, true);
    this.doc.addEventListener('dblclick', this.stopEventAsCapturing, true);
    this.doc.addEventListener('mouseenter', this.stopEventAsCapturing, true);
    this.doc.addEventListener('mouseleave', this.stopEventAsCapturing, true);
  }

  removeEventHandlers() {
    // remove the event handlers from above
    this.doc.removeEventListener('keydown', this.stopEventAsCapturing, true);
    this.doc.removeEventListener('keyup', this.stopEventAsCapturing, true);
    this.doc.removeEventListener('mousedown', this.stopEventAsCapturingKeepDefault, true);
    this.doc.removeEventListener('mousewheel', this.stopEventAsCapturingKeepDefault, true);
    this.doc.removeEventListener('DOMMouseScroll', this.stopEventAsCapturingKeepDefault, true);
    this.doc.removeEventListener('mouseup', this.stopEventAsCapturing, true);
    this.doc.removeEventListener('dblclick', this.stopEventAsCapturing, true);
    this.doc.removeEventListener('mouseenter', this.stopEventAsCapturing, true);
    this.doc.removeEventListener('mouseleave', this.stopEventAsCapturing, true);
  }

  /**
   * Adds CSS styles for highlighting to a given this.doc.
   */
  addHighlightStyles = () => {
    const {
      CURSOR_STYLES,
      OVERLAY_STYLE,
      RECORD_HIGHLIGHT_STYLE,
      COUNTEREXAMPLE_HIGHLIGHT_STYLE,
      EXAMPLE_HIGHLIGHT_STYLE,
      HOVER_HIGHLIGHT_STYLE,
      SUGGESTION_HIGHLIGHT_STYLE,
      DATA_HIGHLIGHT_STYLE,
    } = constants;
    const style = this.doc.createElement('style');
    style.innerHTML = `${CURSOR_STYLES} ${OVERLAY_STYLE} ${RECORD_HIGHLIGHT_STYLE} ${COUNTEREXAMPLE_HIGHLIGHT_STYLE} ${EXAMPLE_HIGHLIGHT_STYLE} ${HOVER_HIGHLIGHT_STYLE} ${SUGGESTION_HIGHLIGHT_STYLE} ${DATA_HIGHLIGHT_STYLE}`;
    style.type = 'text/css';
    this.doc.head.appendChild(style);
  }

  clearCursors = (el) => {
    Array.from(el.classList).forEach((c) => { if (c.startsWith('io-cursor-')) el.classList.remove(c); });
    return el;
  }

  removeAllCursors() {
    Array.from(this.doc.querySelectorAll(`.${constants.ADD_CLASS}`)).forEach((el) => el.classList.remove(constants.ADD_CLASS));
    Array.from(this.doc.querySelectorAll(`.${constants.DELETE_CLASS}`)).forEach((el) => el.classList.remove(constants.DELETE_CLASS));
    Array.from(this.doc.querySelectorAll(`.${constants.NOT_ALLOWED_CLASS}`)).forEach((el) => el.classList.remove(constants.NOT_ALLOWED_CLASS));
  }

  notAllowedCursor = (el) => {
    this.clearCursors(el);
    el.classList.add(constants.NOT_ALLOWED_CLASS);
  }

  /**
   * Reposition overlays for a specific class
   * @param  {String} clazz
   * @param  {Array<this.state.doc elts
   * TODO: Move to the component that handles the iframe?
   */
  reposOverlays(clazz) {
    this.overlays[clazz].forEach((elt) => {
      const rects = elt.getClientRects();
      for (let i = 0; i != rects.length; i++) {
        const rect = rects[i];

        const scrollTop = this.doc.scrollTop || this.body.scrollTop;
        const scrollLeft = this.doc.scrollLeft || this.body.scrollLeft;

        const overlay = elt.__overlays[i];
        overlay.style.top = `${rect.top + scrollTop}px`;
        overlay.style.left = `${rect.left + scrollLeft}px`;
      }
    });
  }

  repositionAllOverlays() {
    Object.keys(this.overlays).forEach((key) => {
      this.reposOverlays(key);
    });
  }

  throttledRepos() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null;
        this.repositionAllOverlays();
      }, 5);
    }
  }

  /**
   * Removes the given highlight class from all elements in a given this.doc.
   * @param {string} clazz CSS class to match.
   */
  removeElementsOfClass = (clazz) => {
    Array.from(this.doc.querySelectorAll(`.${clazz}`)).forEach((el) => el.parentNode && el.parentNode.removeChild(el));
  }

  deleteCursor = (el) => {
    this.clearCursors(el);
    el.classList.add(constants.DELETE_CLASS);
  }

  addCursor = (el) => {
    this.clearCursors(el);
    el.classList.add(constants.ADD_CLASS);
  }

  confirm = (questionId, callback) => {
    const { showWebsiteViewConfirmModal } = this.props;
    if (showWebsiteViewConfirmModal) {
      const prefix = constants.WEBSITE_VIEW_MESSAGE_MAP[questionId];
      showWebsiteViewConfirmModal(prefix, callback);
    }
  };

  /**
   * @param  {Element} el
   * @return {Boolean} whether or not this element is OK for the current selected field as an example
   * TODO: Move to the component that handles the iframe?
   */
  acceptableExample = (el) => {
    const { field, noField } = this.props;
    return noField || (el && field && (
      ((field.type === 'AUTO' || field.type === 'TEXT') && !isWhitespace(el))
          || ((field.type === 'AUTO' || field.type === 'IMAGE') && el.tagName === 'IMG')
    ));
  }

  /**
   * Create an overlay above an element
   * @param  {Element} elt
   * @param  {String} clazz CSS class of overlay
   */
  createOverlay = (elt, clazz, elOffset = 0, parent = null) => {
    if (!this.doc || !this.body) {
      throw new Error('Unable to create overlays, document or document body do not exist');
    }
    // Absolutely position a div over each client rect so that its border width
    // is the same as the rectangle's width.
    // Note: the overlays will be out of place if the user resizes or zooms.

    elt.__overlays = [];

    const rects = elt.getClientRects();
    for (let i = 0; i !== rects.length; i++) {
      const rect = rects[i];
      const overlay = this.doc.createElement('div');
      overlay.__elt = elt;

      overlay.setAttribute('class', `${clazz} ${constants.OVERLAY_CLASS_NAME}`);
      overlay.style.top = `${rect.top - elOffset.top}px`;
      overlay.style.left = `${rect.left - elOffset.left}px`;
      // we want rect.width to be the border width, so content width is 2px less.
      overlay.style.width = `${rect.width}px`;
      overlay.style.height = `${rect.height}px`;
      if (parent) {
        parent.appendChild(overlay);
      } else {
        this.body.appendChild(overlay);
      }

      elt.__overlays.push(overlay);
    }
  }

  /**
   * @param  {Element} el [description]
   * @return {Boolean}
   */
  inLink(el) {
    if (!el) return false;
    if (el.tagName === 'A') {
      // not a real link
      return validHref(el.getAttribute('href'));
    }
    return this.inLink(el.parentNode);
  }

  elementClicked(element, isCounterExample = false) {
    this.removeAllCursors();
    if (element.nodeType !== 1) {
      element = element.parentNode;
    }
    this.props.elementClickedCallback(element, isCounterExample, this.doc);
  }

  onElementClick(element, isCounterExample) {
    this.doc.getSelection().removeAllRanges();
    this.removeElementsOfClass(constants.HOVER_HIGHLIGHT_CLASS_NAME);
    this.setState({
      processingClick: true,
    }, () => {
      this.elementClicked(element, isCounterExample);
      this.setState({
        processingClick: false,
      });
    });
  }

  /**
   * Add field highlight class to elements that match a field selector, within
   * elements that match a record selector.
   */
  // TODO: Make something generic within the class to handle this state so it's not tied to builder???
  highlightData() {
    const {
      pageSet, selectedPage, field, noField,
    } = this.props;

    this.dataElements.length = 0;
    if (pageSet) {
      if (field && this.doc) {
        let elements = getRecordElements(this.doc, pageSet, true);
        if (!elements || elements.every((e) => !e)) elements = [this.body];
        elements.forEach((record) => {
          if (record) {
            let elts = selectNodes(field, record, true);
            if (!Array.isArray(elts)) {
              elts = [elts];
            }
            elts = elts.filter((e) => typeof e === 'object');
            this.dataElements.push(...elts);
          }
        });
      }
      this.removeElementsOfClass(constants.EXAMPLE_HIGHLIGHT_CLASS_NAME);
      this.redrawOverlays(constants.DATA_HIGHLIGHT_CLASS_NAME, this.dataElements);

      if ((noField || canTrain(field)) && constants.DRAW_SUGGESTIONS) {
        this.suggestionElements.length = 0;
        suggestElements(selectedPage, field).forEach((xpath) => {
          const el = getByFullXPath(xpath, this.doc);
          if (el && !this.dataElements.includes(el)) {
            this.suggestionElements.push(el);
          }
        });
        this.redrawOverlays(constants.SUGGESTION_HIGHLIGHT_CLASS_NAME, this.suggestionElements);
      }
    }
  }

  removeExample(element, isCounterExample) {
    this.props.removeExample(element, isCounterExample);
  }

  isEnabled() {
    return typeof (this.props.enabled) === 'undefined' || this.props.enabled
  }

  refreshHoverElement({ contentElement, elementMouseIsOver }) {
    if (!this.isEnabled()) { return; }
    delete this.onClick;

    this.currentHover = { contentElement, elementMouseIsOver };

    this.removeElementsOfClass(constants.HOVER_HIGHLIGHT_CLASS_NAME);

    const elOffset = this.getGlobalElementOffset();
    const { field, noField } = this.props;
    const { exampleElements, counterExampleElements } = this.props;
    if (field || (noField && exampleElements && counterExampleElements)) {
      let elt;
      if (elt = exampleElements.find((e) => e === contentElement || e.contains(contentElement))) {
        // this is something to be removed
        this.deleteCursor(elementMouseIsOver);

        // remove the example
        this.onClick = () => {
          if (!this.isEnabled()) { return; }
          this.removeExample(elt, exampleElements)
        };
      } else if (elt = counterExampleElements.find((e) => e === contentElement || e.contains(contentElement))) {
        // this is something to be removed
        this.addCursor(elementMouseIsOver);

        // remove the example
        this.onClick = () => {
          if (!this.isEnabled()) { return; }
          this.removeExample(elt, counterExampleElements)
        };
      } else if (elt = this.dataElements.find((e) => e === contentElement || e.contains(contentElement))) {
        // this is something to be removed
        this.deleteCursor(elementMouseIsOver);

        // remove the example
        this.onClick = () => {
          if (!this.isEnabled()) { return; }
          this.onElementClick(elementMouseIsOver, true)
        };
      } else if (this.acceptableExample(contentElement)
                  && (!constants.DRAW_SUGGESTIONS || !this.suggestionElements.find((e) => e === contentElement || e.contains(contentElement) || contentElement.contains(e)))) {
        this.createOverlay(contentElement, constants.HOVER_HIGHLIGHT_CLASS_NAME, elOffset);
        this.addCursor(elementMouseIsOver);

        this.onClick = () => {
          if (!this.isEnabled()) { return; }
          this.detectElementType(contentElement);
        };
      } else { // not an element we can handle
        this.notAllowedCursor(elementMouseIsOver);
      }
    } else if (noField) {
      this.createOverlay(contentElement, constants.HOVER_HIGHLIGHT_CLASS_NAME, elOffset);
      this.addCursor(elementMouseIsOver);

      this.onClick = () => {
        if (!this.isEnabled()) { return; }
        this.onElementClick(contentElement);
      };
    } else { // no selected field
      this.notAllowedCursor(elementMouseIsOver);
    }
  }

  onMouseMove(e) {
    if (!this.isEnabled()) { return; }
    this.stopEventAsCapturing(e);

    // the elemnt that the browser thinks it is over, i.e. the one that gets mouse events
    const elementMouseIsOver = this.doc.elementFromPoint(e.clientX, e.clientY);

    const findAcceptableElement = () => {
      // the elements that look good, i.e. are under the omouse and have content
      const matchingElements = [];

      const altered = [];
      try {
        let el = elementMouseIsOver;
        while (el && el !== this.body) {
          // is this acceptable?
          if (this.acceptableExample(el)) {
            matchingElements.push(el);
          }

          // there are instances, e.g. the images in https://www.etsy.com/uk/c/jewelry/body-jewelry?ref=catnav-1179, where
          // for some reason an ancestor of the actual element you are hoving over is getting the pointer events - not sure why!
          // find all the descendents of the element that the mouse is over that are acceptable
          const matchingDesc = Array.from(elementMouseIsOver.querySelectorAll('*')).filter((el) => {
            const b = el.getBoundingClientRect();
            return b.height * b.width > 0 && b.right >= e.clientX && b.left <= e.clientX && b.top <= e.clientY && b.bottom >= e.clientY && this.acceptableExample(el);
          });
          matchingElements.push(...matchingDesc);

          if (matchingElements.length) {
            // let's pick the deepest acceptable element
            return matchingElements[matchingElements.length - 1];
          }

          // X-ray: let's look at elements behind this element if we don't have content below the cursor
          // make the element invisible so we can get the next element under it
          altered.push({
            el,
            style: el.style.visibility,
          });
          el.style.visibility = 'hidden';
          const newEl = this.doc.elementFromPoint(e.clientX, e.clientY);
          if (el === newEl) break;
          el = newEl;
        }
      } finally {
        // reset the visiblity
        altered.forEach(({
          el,
          style,
        }) => el.style.visibility = style);
      }
    };

    const contentElement = findAcceptableElement();
    // no content or same as before
    if (!contentElement || (this.currentHover && this.currentHover.contentElement === contentElement)) {
      return;
    }

    this.refreshHoverElement({
      contentElement,
      elementMouseIsOver,
    });
  }

  /**
   * Initialises the current document
   */
  initDoc = () => {
    if (this.isEnabled()) this.setEventHandlers();
    this.addHighlightStyles();

    const elts = Array.from(this.doc.querySelectorAll('html body *'));
    if (this.props.enabled) {
      elts.forEach(this.notAllowedCursor);
    }
    elts.forEach((elt) => elt.addEventListener('scroll', this.throttledRepos));

    // update the UI when the mouse moves
    this.doc.addEventListener('mousemove', this.onMouseMove, true);

    const onClick = (e) => {
      if (!this.isEnabled()) {
        return;
      }
      // One more approach to preventing multiple events from firing for a single click.
      if (this.lastEvent
         && e.isTrusted === this.lastEvent.isTrusted
         && e.screenX === this.lastEvent.screenX
         && e.screenY === this.lastEvent.screenY
         && e.clientX === this.lastEvent.clientX
         && e.clientY === this.lastEvent.clientY) {
        return;
      }
      this.lastEvent = e;

      this.stopEventAsCapturing(e);

      // A different approach to preventing accidental double clicks.
      // Only a single event should slip through.
      // Previous, timeout based approach, not always worked, and relied on app performance.
      // This one should be more generic.
      if (e.detail !== 1) {
        return;
      }

      if (this.onClick) {
        this.onClick();
      }

      this.refreshHoverElement(this.currentHover);
    };

    this.doc.addEventListener('click', onClick);
  }

  findBodyElement(element) {
    for (const child of element.children) {
      if (child.tagName === 'BODY') {
        return child;
      }
    }
    for (const child of element.children) {
      const body = this.findBodyElement(child);
      if (body) {
        return body;
      }
    }
    return null;
  }

  onLoad(iframeWindow) {
    const doc = iframeWindow.document;
    if ((!doc.body || !doc.body.getClientRects)) {
      const body = this.findBodyElement(doc);
      if (!body) {
        throw new Error('Unable to locate document body');
      }
      this.body = body;
      // throw new Error('Unable to initialize window');
    } else {
      this.body = doc.body;
    }
    this.doc = doc;
    this.props.onLoadCallback(this.doc);
    iframeWindow.addEventListener('resize', this.throttledRedraw);
    initializeEditorDocument(this.doc);
    this.initDoc();
  }

  doClickFactory(contentElement) {
    return (captureLink) => {
      this.props.onCaptureLink(captureLink);
      this.onElementClick(contentElement);
    };
  }

  clearColumnAndClickFactory(contentElement) {
    return (clearColumn) => {
      if (clearColumn) {
        this.props.onClearSelectedExamples();
        this.onElementClick(contentElement);
      }
    };
  }

  // Detect if the element field type invoke the necessary functions
  detectElementType = (contentElement, shouldShowLinkModal = true) => {
    const { field, noField } = this.props;
    if (field && !canTrain(field)) {
      this.confirm('CLEAR_AND_TRAIN', this.clearColumnAndClickFactory);
      return;
    }

    if (!noField && field.type === 'AUTO') {
      this.props.changeSelectedFieldType(contentElement.tagName === 'IMG' ? 'IMAGE' : 'TEXT');
      if (this.inLink(contentElement)) {
        if (shouldShowLinkModal) {
          this.confirm('CAPTURE_LINK', this.doClickFactory(contentElement));
          return;
        }
        this.doClickFactory(contentElement)(true);
        return;
      }
    }
    this.onElementClick(contentElement);
  }

  render() {
    const { children } = this.props;
    const childrenWithProps = React.Children.map(children, (child) => (
      React.cloneElement(child, {
        hiddenFrame: this.props.hideFrame,
        page: this.props.selectedPage,
        isRefreshingPage: !!this.props.refreshingPageId,
        onLoad: this.onLoad,
        stopLoading: this.props.stopLoading,
      })
    ));
    return (
      <>
        {childrenWithProps}
      </>
    );
  }
}
