import cloneDeep from 'lodash/cloneDeep';
import createInteractionAction from '../utils/InteractionAction';
import jssdk from '../utils/jssdk';
import { createActionList } from '../utils/interactionUtils';

export const UPDATE_WEBSITE_CONSOLE = 'UPDATE_WEBSITE_CONSOLE';
export const SET_VIRTUAL_BROWSER_ID = 'SET_VIRTUAL_BROWSER_ID';
export const SET_EXTRACTOR_MEMORY = 'SET_EXTRACTOR_MEMORY';
export const SET_PRIVATE_SESSION = 'SET_PRIVATE_SESSION';
export const UPDATE_INTERACTION_STATE = 'UPDATE_INTERACTION_STATE';
export const PLAY_ACTION = 'PLAY_ACTION';
export const REPLAY_AUTH_INTERACTIONS = 'REPLAY_AUTH_INTERACTIONS';
export const REPLAY_INTERACTIONS = 'REPLAY_INTERACTIONS';
export const STOP_REPLAY = 'STOP_REPLAY';
export const RECEIVED_DATA = 'RECEIVED_DATA';
export const SET_AUTO_REPLAY = 'SET_AUTO_REPLAY';
export const SET_ACE_CURSOR_POSITION = 'SET_ACE_CURSOR_POSITION';
export const SET_INTERACTION_VIEW = 'SET_INTERACTION_VIEW';
export const REPLAY_FULL_SEQUENCE = 'REPLAY_FULL_SEQUENCE';
export const SET_COMPILING_EXTRACTOR = 'SET_COMPILING_EXTRACTOR';
export const SET_COMPILING_AUTH_EXTRACTOR = 'SET_COMPILING_AUTH_EXTRACTOR';
export const AUTH_FINISHED = 'AUTH_FINISHED';
export const INTERACTIONS_FINISHED = 'INTERACTIONS_FINISHED';
export const SET_WEBSOCKET = 'SET_WEBSOCKET';

export const setExtractorMemory = (memory) => ({ type: SET_EXTRACTOR_MEMORY, memory });
export const setPrivateSession = (privateSession) => ({ type: SET_PRIVATE_SESSION, privateSession });
export const updateWebsiteConsole = (websiteConsole) => ({ type: UPDATE_WEBSITE_CONSOLE, websiteConsole });
export const playAction = (currentAction) => ({ type: PLAY_ACTION, currentAction });
export const replayAuth = () => ({ type: REPLAY_AUTH_INTERACTIONS });
export const replayInteractions = () => ({ type: REPLAY_INTERACTIONS });
export const stopReplay = () => ({ type: STOP_REPLAY });
export const receivedData = (data = {}) => ({ type: RECEIVED_DATA, data });
export const setAutoReplay = (autoReplay) => ({ type: SET_AUTO_REPLAY, autoReplay });
export const setAceCursorPosition = (cursorPosition) => ({ type: SET_ACE_CURSOR_POSITION, cursorPosition });
export const setInteractionView = (selectedView) => ({ type: SET_INTERACTION_VIEW, selectedView })
export const replayFullSequence = () => ({ type: REPLAY_FULL_SEQUENCE })
export const setCompilingExtractor = (compilingExtractor) => ({ type: SET_COMPILING_EXTRACTOR, compilingExtractor })
export const setCompilingAuthExtractor = (compilingAuthExtractor) => ({ type: SET_COMPILING_AUTH_EXTRACTOR, compilingAuthExtractor })
export const authFinished = (error) => ({ type: AUTH_FINISHED, error })
export const interactionsFinished = () => ({ type: INTERACTIONS_FINISHED })
export function setWebsocket(websocket) {
  return { type: SET_WEBSOCKET, websocket };
}

export function logToConsole(...args) {
  return (dispatch, getState) => {
    if (!Array.isArray(args)) args = [args];

    let { websiteConsole } = cloneDeep(getState().interactions)
    // add all to existing console stack, but make sure all objects are pure objects,
    // otherwise won't work with the ui component
    websiteConsole = [...websiteConsole, ...args].map((a) => (typeof a !== 'object' || Array.isArray(a) ? a : { ...a }))
    dispatch(updateWebsiteConsole(websiteConsole))
  }
}

export function setVirtualBrowserId({ virtualBrowserId }) {
  return (dispatch) => {
    dispatch(logToConsole(`Virtual Browser Updated: ${virtualBrowserId}`))
    dispatch({ type: SET_VIRTUAL_BROWSER_ID, virtualBrowserId })
  }
}

export function updateInteractionState(newState) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_INTERACTION_STATE,
      ...newState,
    })
  }
}

export function newActionRecorded(action, actionId) {
  return (dispatch, getState) => {
    const { newActions } = cloneDeep(getState().interactions)
    newActions.push(createInteractionAction({ action, actionId }))
    dispatch(updateInteractionState({ newActions }))
  };
}

export function addAction(action, actionId) {
  return async (dispatch, getState) => {
    const { authInteractions, actionList, selectedView } = cloneDeep(getState().interactions)

    if (!['actionList', 'authInteractions'].includes(selectedView)) return;
    const isAuth = selectedView === 'authInteractions';
    const recordingIntoList = isAuth ? authInteractions : actionList;
    recordingIntoList.push(createInteractionAction({ action, actionId, isAuth }))

    await dispatch(updateInteractionState({ actionList, authInteractions }))
  }
}

export function updateAction(action, isAuth) {
  return (dispatch, getState) => {
    const { actionList, authInteractions } = cloneDeep(getState().interactions);
    const actionListWeAreUsing = (isAuth || action.isAuth) ? authInteractions : actionList;
    actionListWeAreUsing.forEach((a, idx) => {
      if (action.parentActionId && a.actions && a.actionId === action.parentActionId) {
        const childActionIndex = a.actions.findIndex((a) => a.actionId === action.actionId);
        let childAction = a.actions[childActionIndex];
        if (childAction) {
          childAction = Object.assign(action, {});
          a.actions.splice(childActionIndex, 1, childAction);
        }
        a.actions = a.actions.slice(); // resets browser actions
      } else if (a.actionId === action.actionId) {
        actionListWeAreUsing[idx] = Object.assign(action, {});
      }
    });
    dispatch(updateInteractionState({ actionList, authInteractions }));
  };
}

export function loadFromRuntimeConfig(runtimeConfigGuid = '', currentInput = {}, credentialsInput = {}) {
  return async (dispatch) => {
    try {
      const runtimeConfig = await jssdk.runtimeConfiguration.get(runtimeConfigGuid);
      if (!runtimeConfig) return;

      const {
        interactions, authInteractions, inputSchema, outputSchema,
      } = runtimeConfig.config;
      await dispatch(updateInteractionState({
        actionList: createActionList(interactions),
        authInteractions: createActionList(authInteractions || []),
        currentInput,
        credentialsInput,
        inputSchema,
        outputSchema,
      }))
      dispatch(replayFullSequence())
    } catch (e) {
      console.error('Error fetching runtime config', e);
    }
  }
}
