export const WS_DEFAULT_SETTINGS = {
  images_enabled: true,
  css_enabled: true,
  load_all_resources: true,
}
export const DEFAULT_HORIZONTAL_SIZE = 500
export const DRAWER_WIDTH = 600
export const TRAINING_MODE = 'TRAINING_MODE';
export const INTERACTIONS_MODE = 'INTERACTIONS_MODE';
export const TEMPLATE_TRAINING_MODE = 'TEMPLATE_TRAINING_MODE';
export const ENVIRONMENTS = {
  PROD: 'import.io',
  DEMO: 'demo-owl.com',
  STAGE: 'staging-owl.com',
};

export const TEMPLATE_FIELD_TYPES = {
  STRING_FIELD: 'StringField',
  BOOLEAN_FIELD: 'BooleanField',
  URL_FIELD: 'UrlField',
  NUMBER_FIELD: 'NumberField',
  CURRENCY_FIELD: 'CurrencyField',
}
