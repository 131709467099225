import { Field, PageSet } from '@import/web-extractor';
import { TYPES } from '../actions/templateTraining';
import { TEMPLATE_FIELD_TYPES } from '../utils/constants';

function createInitialState() {
  return {
    typeToTrain: TEMPLATE_FIELD_TYPES.STRING_FIELD,
    selector: {
      type: 'css',
      selector: '',
    },
    regex: '',
    regexReplace: '',
    validRegex: true,
    field: new Field({}),
    pageSet: new PageSet(),
    templatedFieldObj: null,
  }
}

export default function templateTraining(state = createInitialState(), action) {
  switch (action.type) {
    case TYPES.SELECT_FIELD_TYPE:
      const { fieldType } = action;
      return { ...state, typeToTrain: fieldType };
    case TYPES.SET_SELECTOR:
      const { selector } = action;
      return { ...state, selector };
    case TYPES.SET_REGEX:
      const { regex } = action;
      return { ...state, regex };
    case TYPES.SET_VALID_REGEX:
      const { validRegex } = action;
      return { ...state, validRegex };
    case TYPES.SET_REGEX_REPLACE:
      const { regexReplace } = action;
      return { ...state, regexReplace };
    case TYPES.SET_FIELD:
      const { field } = action;
      return { ...state, field };
    case TYPES.SET_PAGE_SET:
      const { pageSet } = action;
      return { ...state, pageSet };
    case TYPES.RESET_TRAINING:
      return createInitialState();
    case TYPES.SET_TEMPLATED_FIELD_OBJ:
      const { templatedFieldObj } = action;
      return { ...state, templatedFieldObj };
    default:
      return state;
  }
}
