import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import { autobind } from '../../utils/objectUtils';
import { setField } from '../../actions/training';
import { getFieldForEditString } from '../../selectors/training';

function mapStateToProps(state) {
  return {
    fieldString: getFieldForEditString(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setField: (field) => dispatch(setField(field)),
  };
}

class FieldEditor extends React.PureComponent {
  static propTypes = {
    fontSize: PropTypes.number.isRequired,
    theme: PropTypes.string.isRequired,
    wireSaveEditedFieldCallback: PropTypes.func.isRequired,
    fieldString: PropTypes.string.isRequired,
    setField: PropTypes.func.isRequired,
  }

  state = {
    value: '',
  }

  constructor(props) {
    super(props);
    autobind(this);
    props.wireSaveEditedFieldCallback(this);
  }

  componentDidMount() {
    this.exists = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fieldString !== this.props.fieldString) {
      this.setState({
        value: this.props.fieldString,
      });
    }
  }

  componentWillUnmount() {
    this.exists = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.value === '') {
      return { value: nextProps.fieldString };
    }
  }

  saveField() {
    let jsonField;
    try {
      jsonField = JSON.parse(this.state.value);
    } catch {
      return false;
    }
    this.props.setField(jsonField);
    return true;
  }

  onChange(value) {
    this.setState({ value });
  }

  render() {
    const { theme, fontSize } = this.props;
    return (
      <AceEditor
        ref={this.codeEditor}
        style={{ height: '100%', width: '100%' }}
        mode="javascript"
        theme={theme}
        name="copyHistory"
        key="copyHistory"
        editorProps={{ $blockScrolling: Infinity }}
        fontSize={fontSize}
        showGutter={false}
        onChange={this.onChange}
        value={this.state.value}
        highlightActiveLine
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldEditor);
