import * as jssdk from '@import/js-sdk';

export function createSdk({ apiKey, environment }) {
  if (environment) jssdk.setEndpointsRootDomain(environment)
  Object.keys(jssdk).forEach((key) => {
    if (typeof jssdk[key] !== 'function') {
      const sdk = new jssdk[key].constructor();
      sdk.apiKey = apiKey;
      jssdk[key] = sdk;
    }
  });
}

export default jssdk;
