import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

function JsonView({ value }) {
  return (
    <ReactJson
      src={value}
      name={false}
      iconStyle="triangle"
      displayObjectSize={false}
      displayDataTypes={false}
      theme="monokai"
      style={{ fontSize: '12px', height: '100%' }}
    />
  )
}

JsonView.propTypes = {
  value: PropTypes.any,
}

export default JsonView
