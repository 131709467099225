import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ServerBrowserApi from '@import/page-interaction-sdk/dist/ServerBrowserApi';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { hot } from 'react-hot-loader';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getIsReplaying } from '../selectors/interactions'
import { DRAWER_WIDTH } from '../utils/constants';
import * as Interactions from '../actions/interactions';

const SESSION_EXPIRED_MESSAGE = 'Your session has expired and must be restored before interacting with the webpage'
const MAX_RETRIES = 5;
const DATA_RETURNED_MESSAGE = 'Data returned'

function mapStateToProps(state) {
  return {
    ...state.interactions,
    isReplaying: getIsReplaying(state),
    apiKey: state.user.apiKey,
    chromiumEndpoint: state.app.chromiumEndpoint,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Interactions, dispatch),
  }
}

const styles = (theme) => ({
  iframe: {
    height: 'calc(100vh - 25px)',
    padding: '0px 25px',
  },
  iframeDrawerOpen: {
    width: `calc(100vw - ${DRAWER_WIDTH}px)`,
  },
  iframeDrawerClosed: {
    width: '100vw',
  },
  closeSnackbar: {
    padding: theme.spacing.unit / 2,
  },
});

class InteractionFrame extends Component {
  static propTypes = {
    authInteractions: PropTypes.array.isRequired,
    actionList: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      updateInteractionState: PropTypes.func.isRequired,
      setVirtualBrowserId: PropTypes.func.isRequired,
      setExtractorMemory: PropTypes.func.isRequired,
      updateWebsiteConsole: PropTypes.func.isRequired,
      logToConsole: PropTypes.func.isRequired,
      playAction: PropTypes.func.isRequired,
      stopReplay: PropTypes.func.isRequired,
      receivedData: PropTypes.func.isRequired,
      newActionRecorded: PropTypes.func.isRequired,
      authFinished: PropTypes.func.isRequired,
      interactionsFinished: PropTypes.func.isRequired,
      setInteractionView: PropTypes.func.isRequired,
      setWebsocket: PropTypes.func.isRequired,
    }).isRequired,
    privateSession: PropTypes.bool.isRequired,
    virtualBrowserId: PropTypes.string.isRequired,
    websiteConsole: PropTypes.array.isRequired,
    onLoadingChange: PropTypes.func.isRequired,
    currentAction: PropTypes.object,
    isReplaying: PropTypes.bool.isRequired,
    authStatus: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    isSidebarOpen: PropTypes.bool.isRequired,
    isReplayingAuth: PropTypes.bool.isRequired,
    isReplayingInteractions: PropTypes.bool.isRequired,
    onLoad: PropTypes.func,
    isReplayingFullSequence: PropTypes.bool.isRequired,
    chromiumEndpoint: PropTypes.string.isRequired,
  }

  state = {
    retries: 0,
    requestsFinished: false,
    assetsLoaded: false,
    isWsLoading: false,
    snackbarOpen: false,
    snackbarMessage: '',
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isReplayingAuth && this.props.isReplayingAuth) {
      this.replayAuthInteractions()
    }

    if ((!prevProps.isReplayingInteractions && this.props.isReplayingInteractions)) {
      this.replayInteractions()
    }

    if ((!prevProps.isReplayingFullSequence && this.props.isReplayingFullSequence)) {
      this.replayEntireSequence()
    }
  }

  initializeBrowser() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await this.reset(this.ws)
      const {
        privateSession, virtualBrowserId, apiKey, chromiumEndpoint,
      } = this.props
      const apiKeyParam = apiKey ? `&_apikey=${apiKey}` : ''
      let loaded = false;
      try {
        this.ws = new ServerBrowserApi(
          document.querySelector('#iframe-wrapper'),
          `${chromiumEndpoint}?totalExecutionTimeout=0${apiKeyParam}`,
          {
            activityTimeout: 1800000,
            privateSession,
            virtualBrowserId,
            captcha: true,
          },
        );
        this.ws.on('ready', () => {
          const onLoad = _.debounce((window) => {
            if (!loaded) {
              this.props.onLoad(window);
              loaded = true;
            }
          }, 2000);
          console.log(this.ws.iframe);
          this.ws.iframe.window.addEventListener('DOMNodeInserted', () => onLoad(this.ws.iframe.window));
          resolve()
        });
        this.ws.on('close', this.onClose)

        // TODO: Record new action stuff
        this.ws.on('new-control-action', this.onNewAction.bind(this))
        // this.ws.on('new-control-action-initiated', (action, actionId) => this.onNewAction(action, actionId, 'saving'))
        // this.ws.on('new-control-action-failed', this.onNewActionFailed)
        this.ws.on('network-monitor', this.onNetworkMonitor.bind(this))
        this.ws.on('asset-proxying-counter', this.onAssetProxy.bind(this))

        this.ws.on('virtual-browser-updated', this.props.actions.setVirtualBrowserId.bind(this))
        // this.ws.on('allow-popup-prompt', this.onPopupDetected)
        this.ws.on('memory-updated', this.props.actions.setExtractorMemory.bind(this))
        this.ws.on('website-console-updated', this.onConsoleUpdated.bind(this))
        this.props.actions.setWebsocket(this.ws);
      } catch (e) {
        console.error(e);
        console.error('initializing browser failed');
        this.props.actions.setWebsocket(null);
        reject(e);
      }
    })
  }

  async replayAuthInteractions() {
    try {
      await this.initializeBrowser()
      const { authInteractions } = this.props
      await this.replay(authInteractions)
      this.props.actions.authFinished()
    } catch (err) {
      this.props.actions.authFinished(err)
    }
  }

  async replayInteractions() {
    try {
      await this.initializeBrowser()
      const { actionList } = this.props
      await this.replay(actionList)
      this.props.actions.interactionsFinished()
    } catch (err) {
      this.props.actions.interactionsFinished(err)
    }
  }

  async replayEntireSequence() {
    await this.replayAuthInteractions()
    this.props.actions.setInteractionView('actionList')
    await this.replayInteractions()
  }

  async replay(actions) {
    this.props.actions.receivedData({})
    if (!this.ws && this.state.retries < MAX_RETRIES) {
      const retries = this.state.retries + 1
      await this.setState({ retries })
      await this.initializeBrowser();
      this.replay(actions)
    }

    this.setState({ retries: 0 });

    await Promise.each(actions, async (action, index) => {
      if (!this.props.isReplaying) return Promise.resolve()
      if (index > 0) {
        await Promise.delay(10);
      }

      const { currentInput, credentialsInput } = this.props;

      // updates current action in app state
      this.props.actions.playAction(action);

      const playbackInput = {
        ...currentInput,
        _credentials: credentialsInput,
      };

      try {
        const result = await this.ws.play(action.browserAction, playbackInput)

        // Data was returned during playback
        if ((action.name === 'FunctionAction' || action.name === 'CodeAction')
              && result && result._taskProcessingAction === 'ReturnDataAndBreak') {
          const returnData = result.data
          this.props.actions.logToConsole('Data returned:', returnData)
          this.props.actions.receivedData(returnData)
          return Promise.resolve(DATA_RETURNED_MESSAGE)
        }

        if (action.name === 'ScrollAction'
                && typeof action.browserAction.toJSON === 'function') {
          return Promise.resolve(this.ws.playInFrame(action.browserAction.toJSON()));
        }
        return Promise.resolve();
      } catch (err) {
        if (err) {
          const { name } = this.props.currentAction;
          let errorMessage = 'An error occured during playback.';
          if (this.props.authStatus === 'replaying-auth') {
            errorMessage = 'Your login failed during playback';
          } else if (this.props.currentAction && (name === 'FunctionAction' || name === 'CodeAction')) {
            errorMessage = `An error occured during a JavaScript Action: ${err.message}`;
          }

          this.props.actions.logToConsole(errorMessage, err)
          return Promise.reject(err);
        }
      }
    })
  }

  onNewAction(action) {
    this.props.actions.newActionRecorded(action)
  }

  onNetworkMonitor({ pendingRequests, finishedRequests }) {
    const networkProgress = finishedRequests / (pendingRequests + finishedRequests)
    let requestsFinished = false
    if (networkProgress === 1) {
      requestsFinished = true
    } else if (networkProgress < 1 && this.state.requestsFinished) {
      requestsFinished = false
    }

    if (requestsFinished !== this.state.requestsFinished) {
      this.setState({ requestsFinished }, this.checkForLoadingUpdate)
    }
  }

  onAssetProxy(counter) {
    const assetsLoaded = (counter === 0)
    if (assetsLoaded !== this.state.assetsLoaded) {
      this.setState({ assetsLoaded }, this.checkForLoadingUpdate)
    }
  }

  checkForLoadingUpdate() {
    const { assetsLoaded, requestsFinished } = this.state
    let { isWsLoading } = this.state

    if (isWsLoading && assetsLoaded && requestsFinished) {
      isWsLoading = false
      this.props.onLoadingChange(isWsLoading)
      this.setState({ isWsLoading })
    } else if (!isWsLoading && (!assetsLoaded || !requestsFinished)) {
      isWsLoading = true
      this.props.onLoadingChange(isWsLoading)
      this.setState({ isWsLoading })
    }
  }

  onConsoleUpdated(args) {
    const { websiteConsole } = this.props
    if (Array.isArray(args)) {
      const [level, ...rest] = args
      if (Array.isArray(rest)) {
        rest.forEach((val) => websiteConsole.push(`${level}: ${val}`))
      } else {
        websiteConsole.push(`${level}: ${rest}`);
      }
    }
    this.props.actions.updateWebsiteConsole(websiteConsole)
  }

  async onClose(data, error) {
    if (!this.ws) return
    this.ws = null

    if (data instanceof CloseEvent) {
      // Either we lost the connection or we are restarting it (for playback, etc)
      if (data.wasClean === false) {
        // We lost the connection
        console.error('Connection closed unexpectantly', data, error)
        this.openSnackbar('Connection to the browser was interrupted')
        return this.props.actions.logToConsole(SESSION_EXPIRED_MESSAGE)
      }
      // We're restarting it
      console.log('restarting websocket connection')
      console.log('Websocket connection closed: ', data)
    } else if (data === 'activity timeout') {
      this.openSnackbar('Connection to the browser timed out')
      return this.props.actions.logToConsole(SESSION_EXPIRED_MESSAGE)
    } else if (data === 'cannot load first page' || data === 'blocked page detected') {
      // We got blocked, so let's retry if we haven't already
      console.error(`Retrying for Error: ${data}, retry number ${this.state.retries}`)
      if (this.state.retries < 10) {
        return this.retryConnection()
      }
      if (this.state.retries) {
        this.props.actions.logToConsole('Failed to connect to website. You may try another URL')
      }
    }
  }

  async retryConnection() {
    this.props.actions.logToConsole('Trying to establish connection...')
    const retries = this.state.retries + 1
    await this.setState({ retries })
    this.ws = null
    if (this.props.isReplaying) return this.replayEntireSequence()
  }

  reset() {
    this.props.actions.setWebsocket(null);
    return new Promise((resolve) => {
      if (this.ws) {
        this.ws.iframe.clearPage();
        console.log('Calling disconnect', this.ws);
        this.ws.removeAllListeners('close');
        this.ws.on('close', () => {
          console.log('Disconnected, calling connect');
          this.ws = null;
          resolve();
        })
        if (this.ws.ws) {
          this.ws.disconnect();
        } else {
          this.ws = null;
          resolve();
        }
      } else {
        resolve('No need to reset, client not setup');
      }
    })
  }

  openSnackbar = (snackbarMessage) => this.setState({ snackbarOpen: true, snackbarMessage })

  handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false, snackbarMessage: '' });
  };

  render() {
    const { classes } = this.props
    const { snackbarOpen, snackbarMessage } = this.state
    return (
      <>
        <div
          id="iframe-wrapper"
          className={classNames(classes.iframe, this.props.isSidebarOpen ? classes.iframeDrawerOpen : classes.iframeDrawerClosed)}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackbarOpen}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          action={[
            <IconButton
              key="close1"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          message={<span id="message-id">{snackbarMessage}</span>}
        />
      </>
    )
  }
}

const componentWithStyles = withStyles(styles)(InteractionFrame)

const container = connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actions: dispatchProps,
}))(componentWithStyles)

export default hot(module)(container);
