import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import { debounce } from 'lodash';
import { newFieldDone, setTempField } from '../../actions/training';
import { autobind } from '../../utils/objectUtils';

function mapStateToProps(state) {
  return {
    tempField: state.training.tempField,
  };
}

function mapDispatchToProps(dispatch) {
  const debouncedDispatch = debounce(dispatch, 300);
  return {
    newFieldDone: (field) => dispatch(newFieldDone(field)),
    setTempField: (field) => debouncedDispatch(setTempField(field)),
  };
}

class FieldEditor extends React.PureComponent {
  static propTypes = {
    fontSize: PropTypes.number.isRequired,
    theme: PropTypes.string.isRequired,
    tempField: PropTypes.string.isRequired,
    newFieldDone: PropTypes.func.isRequired,
    setTempField: PropTypes.func.isRequired,
    wireSaveNewFieldCallback: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.tempField,
    };
    autobind(this);
    props.wireSaveNewFieldCallback(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.tempField !== prevProps.tempField) {
      this.setState({ value: this.props.tempField });
    }
  }

  saveField() {
    let jsonField;
    try {
      jsonField = JSON.parse(this.state.value);
    } catch {
      return false;
    }
    this.props.newFieldDone(jsonField);
    return true;
  }

  onChange(value) {
    this.setState({ value });
    this.props.setTempField(value);
  }

  render() {
    const { theme, fontSize } = this.props;
    return (
      <AceEditor
        ref={this.codeEditor}
        style={{ height: '100%', width: '100%' }}
        mode="javascript"
        theme={theme}
        name="copyHistory"
        key="copyHistory"
        editorProps={{ $blockScrolling: Infinity }}
        fontSize={fontSize}
        showGutter={false}
        onChange={this.onChange}
        value={this.state.value}
        highlightActiveLine
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldEditor);
