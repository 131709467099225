import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    fields: state.training.fields,
  };
}

class FieldsViewer extends React.PureComponent {
  static propTypes = {
    theme: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    fontSize: PropTypes.number.isRequired,
  }

  render() {
    const { fontSize, theme, fields } = this.props;
    const value = {
      fields,
    };
    return (
      <AceEditor
        ref={this.codeEditor}
        style={{ height: '100%', width: '100%' }}
        mode="javascript"
        theme={theme}
        name="fields"
        key="fields"
        editorProps={{ $blockScrolling: Infinity }}
        fontSize={fontSize}
        readOnly
        showGutter={false}
        highlightActiveLine
        value={JSON.stringify(value, null, 2)}
      />
    );
  }
}

export default connect(mapStateToProps)(FieldsViewer);
