import React, { Component } from 'react';
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view';
import ReactList from 'react-list';

const
  BOOLEAN_COLOR = '#8E74D3';
const EDITOR_BACKGROUND = '#272722';
const EDITOR_TEXT = '#8DD9EC';
const NULL_COLOR = '#ACD24D';

export default class extends Component {
  static propTypes = {
    values: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.renderLogLine = this.renderLogLine.bind(this)
  }

  renderLogLine(index, key) {
    const val = this.props.values[index]
    return (typeof val === 'object' && val !== null ? (
      <ReactJson
        key={key}
        enableClipboard={false}
        src={val}
        name={false}
        iconStyle="triangle"
        displayObjectSize={false}
        displayDataTypes={false}
        theme="monokai"
        collapsed
        style={{ fontSize: '12px', paddingBottom: 5 }}
      />
    ) : (
      <div
        style={{
          backgroundColor: EDITOR_BACKGROUND,
          paddingLeft: 5,
          fontSize: 12,
        }}
        key={key}
      >
        {(() => {
          if (val === null) {
            return <span style={{ color: NULL_COLOR, fontWeight: 600, paddingBottom: 5 }}>null</span>;
          }
          return typeof val === 'string'
            ? (
              <span style={{ color: EDITOR_TEXT, fontWeight: 600, paddingBottom: 5 }}>
                "
                {val ? val.toString() : ''}
                "
              </span>
            )
            : <span style={{ color: BOOLEAN_COLOR, fontWeight: 600, paddingBottom: 5 }}>{val.toString()}</span>
        })()}
      </div>
    ))
  }

  render() {
    const { values } = this.props
    return (
      <ReactList
        itemRenderer={this.renderLogLine}
        length={values.length}
      />
    )
  }
}
