import 'babel-polyfill';
import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import store from './store/configureStore'
import App from './components/App'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import UserPreferences from './utils/userPreferences'
import { DEFAULT_HORIZONTAL_SIZE } from './utils/constants'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
// eslint-disable-next-line
// import AceEditor from 'react-ace'
import 'brace/mode/javascript'
import 'brace/theme/monokai'
import 'brace/theme/textmate'
import 'brace/theme/twilight'
import 'brace/theme/tomorrow'
import 'brace/theme/kuroir'
import 'brace/theme/solarized_dark'
import 'brace/theme/solarized_light'
import 'brace/theme/terminal'
import 'brace/ext/language_tools'

UserPreferences.configure('thunder-ui-preferences', {
  interactionsOpen: true,
  horizontalHeight: DEFAULT_HORIZONTAL_SIZE,
  editorTabSize: 2,
  editorTheme: 'monokai',
  editorFontSize: 14,
  debuggingToolsOpen: true,
})

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  palette: {
    primary: {
      main: '#0C2448',
    },
    secondary: {
      main: '#CB2257',
    },
    // type: 'dark',
  },
})

const render = () => {
  ReactDOM.render(
    (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </Provider>
    ), document.getElementById('root'),
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
render()
if (module.hot) {
  module.hot.accept('./components/App', () => {
    render()
  })
}
