export const ADD_COPY_HISTORY = 'ADD_COPY_HISTORY';
export const CLEAR_COPY_HISTORY = 'CLEAR_COPY_HISTORY';
export const ADD_FIELD = 'ADD_FIELD';
export const SET_TEMP_FIELD = 'SET_TEMP_FIELD';
export const SET_TEMP_FIELD_OBJ = 'SET_TEMP_FIELD_OBJ';
export const SET_PAGE_SET = 'SET_PAGE_SET';
export const SET_EXAMPLES = 'SET_EXAMPLES';
export const SET_COUNTER_EXAMPLES = 'SET_COUNTER_EXAMPLES';
export const SET_FIELD = 'SET_FIELD';
export const SET_ID_FOR_EDIT = 'SET_ID_FOR_EDIT';

export const NEW_FIELD_SAGA = 'NEW_FIELD_SAGA';
export const NEW_FIELD_DONE_SAGA = 'NEW_FIELD_DONE_SAGA';

export function addCopyHistory(cssSelector) {
  return {
    type: ADD_COPY_HISTORY,
    cssSelector,
  }
}

export function setIdForEdit(id = '') {
  return { type: SET_ID_FOR_EDIT, id };
}

export function clearCopyHistory() {
  return { type: CLEAR_COPY_HISTORY };
}

export function addField(field) {
  return { type: ADD_FIELD, field };
}

export function setField(field) {
  return { type: SET_FIELD, field };
}

export function setTempField(field) {
  return { type: SET_TEMP_FIELD, field };
}

export function newFieldSaga() {
  return { type: NEW_FIELD_SAGA };
}

export function newFieldDone(field) {
  return { type: NEW_FIELD_DONE_SAGA, field };
}

export function setTempFieldObj(field) {
  return { type: SET_TEMP_FIELD_OBJ, field };
}

export function setPageSet(pageSet) {
  return { type: SET_PAGE_SET, pageSet };
}

export function setExamples(examples) {
  return { type: SET_EXAMPLES, examples };
}

export function setCounterExamples(counterExamples) {
  return { type: SET_COUNTER_EXAMPLES, counterExamples };
}

export const TYPES = {
  ADD_COPY_HISTORY,
  CLEAR_COPY_HISTORY,
  ADD_FIELD,
  SET_TEMP_FIELD,
  NEW_FIELD_DONE_SAGA,
  SET_TEMP_FIELD_OBJ,
  SET_FIELD,
  SET_COUNTER_EXAMPLES,
  SET_EXAMPLES,
  SET_ID_FOR_EDIT,
};
