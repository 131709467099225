import { PageSet } from '@import/web-extractor';
import { TYPES } from '../actions/training';

const initialState = {
  copyHistory: [],
  fields: [],
  pageSet: new PageSet(),
  tempField: '',
  tempFieldObj: null,
  fieldsById: {},
  fieldIdForEdit: '',
};

export default function training(state = initialState, action) {
  let field;
  let fieldsById;
  switch (action.type) {
    case TYPES.ADD_COPY_HISTORY:
      const copyHistory = [...state.copyHistory];
      copyHistory.push(action.cssSelector);
      return { ...state, copyHistory };
    case TYPES.CLEAR_COPY_HISTORY:
      return { ...state, copyHistory: [] };
    case TYPES.ADD_FIELD:
      // Making a new fields array will cause reselect selectors to recompute. This is intentional. Keep it in mind.
      const fields = [...state.fields];
      fieldsById = { ...state.fieldsById };
      fieldsById[action.field.id] = action.field;
      fields.push(fieldsById[action.field.id]);
      return { ...state, fields, fieldsById };
    case TYPES.SET_TEMP_FIELD:
      field = action.field;
      return { ...state, tempField: field };
    case TYPES.SET_TEMP_FIELD_OBJ:
      field = action.field;
      return { ...state, tempFieldObj: field };
    case TYPES.SET_PAGE_SET:
      const { pageSet } = action;
      return { ...state, pageSet };
    case TYPES.SET_FIELD:
      fieldsById = { ...state.fieldsById };
      field = fieldsById[action.field.id];
      Object.assign(field, action.field);
      return { ...state, fieldsById };
    case TYPES.SET_ID_FOR_EDIT:
      const { id } = action;
      return {
        ...state,
        fieldIdForEdit: id,
        tempFieldObj: state.fieldsById[id],
      };
    default:
      return state;
  }
}
