import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import copy from 'copy-text-to-clipboard';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { hot } from 'react-hot-loader';
import { bindActionCreators } from 'redux'
import io from 'socket.io-client';
import { connect } from 'react-redux'
import {
  getElementSelector,
  toCssSelector,
  Page,
  trainField,
  calculateAndSetRegionsSelector,
  getByFullXPath,
} from '@import/web-extractor';
import getElementXpath from 'element-xpath';
import { parse } from 'query-string';
import Logo from '../import-logo-primary.png';
import Sidebar from './Sidebar';
import InteractionFrame from './InteractionFrame';
import { DRAWER_WIDTH, TEMPLATE_TRAINING_MODE, TRAINING_MODE } from '../utils/constants';
import {
  updateInteractionState,
  replayAuth,
  replayInteractions,
  replayFullSequence,
  setCompilingExtractor,
  setCompilingAuthExtractor,
  loadFromRuntimeConfig,
} from '../actions/interactions';
import {
  cliModeDetected, setTypeScriptProject, cliConnected, cliConnecting, cliDisconnected, userEnvironmentOverride,
} from '../actions/app'
import { createActionList } from '../utils/interactionUtils'
import {
  addCopyHistory,
  setTempFieldObj,
  setTempField,
  setExamples,
  setCounterExamples,
  setPageSet,
} from '../actions/training';
import { getCurrentUser } from '../actions/user';
import SiteRenderer from './siteRenderer/SiteRenderer';
import * as srConsts from './siteRenderer/constants';
import { autobind } from '../utils/objectUtils';
import EnvironmentPicker from './EnvironmentPicker';
import { FIELD_SETTERS } from '../actions/templateTraining';

function mapStateToProps(state) {
  return {
    actionList: state.interactions.actionList,
    authInteractions: state.interactions.authInteractions,
    currentInput: state.interactions.currentInput,
    credentialsInput: state.interactions.credentialsInput,
    autoReplay: state.interactions.autoReplay,
    cliMode: state.app.cliMode,
    isCliConnecting: state.app.isCliConnecting,
    cursorPosition: state.interactions.cursorPosition,
    sidebarMode: state.app.sidebarMode,
    typescriptProject: state.app.typescriptProject,
    compilingAuthExtractor: state.interactions.compilingAuthExtractor,
    compilingExtractor: state.interactions.compilingExtractor,
    tempFieldObj: state.training.tempFieldObj,
    pageSet: state.training.pageSet,
    examples: state.training.examples,
    counterExamples: state.training.counterExamples,
    templateTrainingField: state.templateTraining.field,
    templateTrainingPageSet: state.templateTraining.pageSet,
    templateTrainingSelectorType: state.templateTraining.selector.type,
    templatedFieldObj: state.templateTraining.templatedFieldObj,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        addCopyHistory,
        cliModeDetected,
        cliConnected,
        cliConnecting,
        cliDisconnected,
        userEnvironmentOverride,
        getCurrentUser,
        replayFullSequence,
        replayAuth,
        replayInteractions,
        setCompilingExtractor,
        setCompilingAuthExtractor,
        setTempFieldObj,
        setTempField,
        setExamples,
        setCounterExamples,
        setPageSet,
        setTypeScriptProject,
        updateInteractionState,
        loadFromRuntimeConfig,
      },
      dispatch,
    ),
    templateTraining: {
      ...bindActionCreators({
        setSelector: FIELD_SETTERS.setSelector,
        setField: FIELD_SETTERS.setField,
        setPageSet: FIELD_SETTERS.setPageSet,
      }, dispatch),
    },
  }
}

function parseObject(input, defaultIt = true) {
  if (!input) {
    return defaultIt ? {} : null
  }
  try {
    if (typeof input === 'object') {
      if (Array.isArray(input)) throw 'Array provided';
      return input
    }
    const val = JSON.parse(input)
    if (val) {
      return val
    }

    return defaultIt ? {} : null
  } catch (e) {
    console.error(e)
    return defaultIt ? {} : null
  }
}

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 24,
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginRight: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    position: 'absolute',
    right: 15,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  close: {
    padding: theme.spacing.unit / 2,
  },
});

const {
  NOT_ALLOWED_CLASS,
  ADD_CLASS,
  DELETE_CLASS,
  RECORD_HIGHLIGHT_CLASS_NAME,
  EXAMPLE_HIGHLIGHT_CLASS_NAME,
  COUNTEREXAMPLE_HIGHLIGHT_CLASS_NAME,
  HOVER_HIGHLIGHT_CLASS_NAME,
  SUGGESTION_HIGHLIGHT_CLASS_NAME,
  DATA_HIGHLIGHT_CLASS_NAME,
  OVERLAY_CLASS_NAME,
} = srConsts;

const reString = `((${NOT_ALLOWED_CLASS})|(${ADD_CLASS})|(${DELETE_CLASS})|(${RECORD_HIGHLIGHT_CLASS_NAME})|`
                 + `(${EXAMPLE_HIGHLIGHT_CLASS_NAME})|(${COUNTEREXAMPLE_HIGHLIGHT_CLASS_NAME})|`
                 + `(${HOVER_HIGHLIGHT_CLASS_NAME})|(${SUGGESTION_HIGHLIGHT_CLASS_NAME})|`
                 + `(${DATA_HIGHLIGHT_CLASS_NAME})|(${OVERLAY_CLASS_NAME}))`;
const REPLACE_RE = new RegExp(reString, 'g');

class App extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      cliModeDetected: PropTypes.func.isRequired,
      cliConnected: PropTypes.func.isRequired,
      cliConnecting: PropTypes.func.isRequired,
      cliDisconnected: PropTypes.func.isRequired,
      updateInteractionState: PropTypes.func.isRequired,
      setTypeScriptProject: PropTypes.func.isRequired,
      replayInteractions: PropTypes.func.isRequired,
      replayAuth: PropTypes.func.isRequired,
      setCompilingExtractor: PropTypes.func.isRequired,
      setCompilingAuthExtractor: PropTypes.func.isRequired,
      replayFullSequence: PropTypes.func.isRequired,
      addCopyHistory: PropTypes.func.isRequired,
      setTempFieldObj: PropTypes.func.isRequired,
      setTempField: PropTypes.func.isRequired,
      setExamples: PropTypes.func.isRequired,
      setCounterExamples: PropTypes.func.isRequired,
      setPageSet: PropTypes.func.isRequired,
      userEnvironmentOverride: PropTypes.func.isRequired,
      getCurrentUser: PropTypes.func.isRequired,
      loadFromRuntimeConfig: PropTypes.func.isRequired,
      templateTraining: PropTypes.shape({
        setSelector: PropTypes.func.isRequired,
        setField: PropTypes.func.isRequired,
        setPageSet: PropTypes.func.isRequired,
      }).isRequired,
    }),
    autoReplay: PropTypes.bool.isRequired,
    sidebarMode: PropTypes.string.isRequired,
    typescriptProject: PropTypes.bool.isRequired,
    isCliConnecting: PropTypes.bool.isRequired,
    tempFieldObj: PropTypes.object,
    pageSet: PropTypes.object,
    compilingAuthExtractor: PropTypes.bool.isRequired,
    compilingExtractor: PropTypes.bool.isRequired,
    templateTrainingField: PropTypes.object.isRequired,
    templateTrainingPageSet: PropTypes.object.isRequired,
    templateTrainingSelectorType: PropTypes.string.isRequired,
    templatedFieldObj: PropTypes.object,
  };

  state = {
    open: true,
    isWsLoading: false,
    snackbarOpen: false,
    snackbarMessage: '',
    snackbarHideDuration: undefined,
    snackbarAction: [],
  };

  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    const query = (parse(location.search) || {})
    // If we're on localhost and we're running locally and CLI_PORT is specified then try to connect
    // Otherwise if this is a production build and we're on localhost it means we're probably running the CLI
    if (window.location.host.includes('local')
        && ((process.env.NODE_ENV === 'development' && process.env.CLI_PORT)
        || process.env.NODE_ENV === 'production')) {
      this.initCLIWS()
    } else {
      this.props.actions.getCurrentUser()
    }
    if (query.runtimeConfig) {
      const { runtimeConfig, input, credentials } = query;
      this.props.actions.loadFromRuntimeConfig(runtimeConfig, parseObject(input), parseObject(credentials))
    }
  }

  initCLIWS() {
    this.props.actions.cliConnecting()
    this.props.actions.cliModeDetected()

    this.cliWs = io(`localhost:${process.env.CLI_PORT || window.location.port}`)
    this.cliWs.io.reconnectionAttempts(5)
    this.cliWs
      .on('connect', () => {
        this.props.actions.cliConnected()
        this.props.actions.setCompilingExtractor(true)
        this.props.actions.setCompilingAuthExtractor(true)
      })
      .on('disconnect', this.props.actions.cliDisconnected)
      .on('reconnect_attempt', (reconnectionAttempts) => {
        if (reconnectionAttempts === 3) {
          this.showSnackbar({ message: 'Attempting to connect to the CLI, make sure you have it running', duration: 5000 })
        }
        if (!this.props.isCliConnecting) this.props.actions.cliConnecting()
      })
      .on('reconnect_failed', () => {
        this.props.actions.cliDisconnected()
        console.error('RECONNECT FAILED')
        this.showSnackbar({
          message: 'Failed to connect to the CLI',
          actions: [
            <Button
              key="retry"
              color="secondary"
              size="small"
              onClick={() => {
                this.handleSnackbarClose()
                this.initCLIWS()
              }}
            >
              RETRY
            </Button>,
          ],
        })
      })
      .on('environment details', this.props.actions.userEnvironmentOverride)
      .on('initial connection', this.setUpInteractionState)
      .on('file changed', this.onFileChange)
      .on('interactions updated', this.interactionsUpdated)
      .on('authInteractions updated', this.authInteractionsUpdated)
      .on('credentials updated', this.credentialsInputUpdated)
      .on('input updated', this.currentInputUpdated)
      .on('inputSchema updated', this.inputSchemaUpdated)
      .on('outputSchema updated', this.outputSchemaUpdated)
      .on('typescript project detected', () => this.props.actions.setTypeScriptProject())
      .on('compiling extractor', () => this.props.actions.setCompilingExtractor(true))
      .on('compiling authExtractor', () => this.props.actions.setCompilingAuthExtractor(true))
  }

  async interactionsUpdated(newActionList) {
    this.props.actions.setCompilingExtractor(false)
    await this.props.actions.updateInteractionState({ actionList: createActionList(newActionList) })
    if (this.props.autoReplay) {
      this.props.actions.replayInteractions()
    }
  }

  async authInteractionsUpdated(newActionList) {
    this.props.actions.setCompilingAuthExtractor(false)
    await this.props.actions.updateInteractionState({ authInteractions: createActionList(newActionList) })
    if (this.props.autoReplay) {
      this.props.actions.replayAuth()
    }
  }

  async credentialsInputUpdated(input) {
    await this.props.actions.updateInteractionState({ credentialsInput: parseObject(input)._credentials || {} })
    if (this.props.autoReplay) {
      this.props.actions.replayAuth()
    }
  }

  async currentInputUpdated(input) {
    await this.props.actions.updateInteractionState({ currentInput: parseObject(input) })
    if (this.props.autoReplay) {
      this.props.actions.replayInteractions()
    }
  }

  async inputSchemaUpdated(inputSchema) {
    await this.props.actions.updateInteractionState({ inputSchema })
    if (this.props.autoReplay) {
      this.props.actions.replayInteractions()
    }
  }

  async outputSchemaUpdated(outputSchema) {
    await this.props.actions.updateInteractionState({ outputSchema })
    if (this.props.autoReplay) {
      this.props.actions.replayInteractions()
    }
  }

  async setUpInteractionState(config) {
    const { compilingAuthExtractor, compilingExtractor } = this.props
    const {
      interactions,
      authInteractions: serializedAuthActions,
      inputSchema,
      outputSchema,
      input,
      authInput,
      typescriptProject,
    } = config;

    if (typescriptProject && !this.props.typescriptProject) this.props.actions.setTypeScriptProject();
    if (compilingAuthExtractor) this.props.actions.setCompilingAuthExtractor(false);
    if (compilingExtractor) this.props.actions.setCompilingExtractor(false);

    const actionList = createActionList(interactions)
    const authInteractions = createActionList(serializedAuthActions)
    const credentialsInput = parseObject(authInput) || {}
    await this.props.actions.updateInteractionState({
      authInteractions,
      actionList,
      inputSchema: parseObject(inputSchema, false),
      outputSchema: parseObject(outputSchema, false),
      currentInput: parseObject(input),
      credentialsInput: credentialsInput._credentials || credentialsInput,
    })

    this.props.actions.replayFullSequence();
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  handleSnackbarClose() {
    this.setState({ snackbarOpen: false, snackbarHideDuration: undefined });
  }

  copySelector(element, doc) {
    let selector = toCssSelector(getElementSelector(element, doc));
    selector = selector.replace(REPLACE_RE, '');
    let splitSelector = selector.split('.');
    splitSelector = splitSelector.filter((str) => !!str.length);
    selector = splitSelector.join('.');
    copy(selector);
    this.showSnackbar({ message: (<span>CSS selector copied to clipboard</span>), duration: 5000 });
    this.props.actions.addCopyHistory(selector);
  }

  handlePageCreation(pageSet, doc) {
    const { pages } = pageSet;
    let page;
    let createNewPage = true;
    pages.forEach((_page) => {
      if (_page.url === doc.URL) {
        createNewPage = false;
        page = _page;
      }
    });
    if (createNewPage) {
      page = new Page({
        url: doc.URL,
        _html: `<html>${doc.documentElement.innerHTML}</html>`,
        _noscriptHtml: `<html>${doc.documentElement.innerHTML}</html>`,
        _scriptDom: doc,
        _noscriptDom: doc,
      });
      pageSet.pages.push(page);
    }
    return page;
  }

  handleFields(field, pageSet) {
    const { id } = field;
    if (pageSet.fields.length) {
      let fieldInPageSet = false;
      for (let i = 0; i < pageSet.fields.length; i++) {
        const _field = pageSet.fields[i];
        if (_field.id === id) {
          fieldInPageSet = true;
        }
      }
      if (!fieldInPageSet) {
        pageSet.fields.push(field);
      }
    } else {
      pageSet.fields = [field];
    }
  }

  elementClickedCallback(element, isCounterExample, doc) {
    const { sidebarMode } = this.props;
    const {
      actions,
      tempFieldObj,
      templateTrainingSelectorType,
    } = this.props;
    const {
      setTempField,
      setTempFieldObj,
      setPageSet,
      templateTraining,
    } = actions;
    if (sidebarMode === TRAINING_MODE && tempFieldObj) {
      const { pageSet } = this.props;
      const page = this.handlePageCreation(pageSet, doc);
      this.handleFields(tempFieldObj, pageSet);
      trainField(pageSet, page, tempFieldObj, element, isCounterExample);
      calculateAndSetRegionsSelector(pageSet);
      setTempFieldObj(tempFieldObj);
      setTempField(JSON.stringify(tempFieldObj.cloneWithCompactSelector(), null, 2));
      setPageSet(pageSet);
    } else if (sidebarMode === TEMPLATE_TRAINING_MODE && templateTrainingSelectorType === 'css') {
      const pageSet = this.props.templateTrainingPageSet;
      const page = this.handlePageCreation(pageSet, doc);
      const field = this.props.templateTrainingField;
      this.handleFields(field, pageSet);
      trainField(pageSet, page, field, element, isCounterExample);
      if (pageSet.pages.length === 0) {
        pageSet.addPage(page);
      }
      calculateAndSetRegionsSelector(pageSet);
      const clonedField = field.cloneWithCompactSelector();
      templateTraining.setField(field);
      templateTraining.setSelector({ type: templateTrainingSelectorType, selector: clonedField.selector[0][0] });
      templateTraining.setPageSet(pageSet);
    } else if (sidebarMode === TEMPLATE_TRAINING_MODE) {
      getElementXpath(element, (err, xpath) => {
        xpath = xpath.replace(/("|")/g, '\\"');
        if (!err) {
          templateTraining.setSelector({
            type: templateTrainingSelectorType,
            selector: xpath,
          });
        }
      });
    } else {
      this.copySelector(element, doc);
    }
  }

  onFileChange({ directoryEvent, fileName }) {
    if (directoryEvent === 'change') this.showSnackbar({ message: `${fileName} saved`, duration: 5000 })
  }

  showSnackbar({
    message = '',
    duration,
    position = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    actions = [],
  }) {
    if (!duration) {
      actions.push((
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={this.props.classes.close}
          onClick={this.handleSnackbarClose}
        >
          <CloseIcon />
        </IconButton>
      ))
    }
    return this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      snackbarHideDuration: duration,
      snackbarPosition: position,
      snackbarAction: actions,
    })
  }

  render() {
    const {
      classes,
      isCliConnecting,
      tempFieldObj,
      sidebarMode,
      templateTrainingPageSet,
      compilingAuthExtractor,
      compilingExtractor,
      templateTrainingField,
      templatedFieldObj,
      pageSet,
    } = this.props;
    const {
      snackbarHideDuration,
      snackbarMessage,
      snackbarOpen,
      open,
      isWsLoading,
      snackbarPosition,
      snackbarAction,
    } = this.state;
    const showLoader = isWsLoading || isCliConnecting || compilingAuthExtractor || compilingExtractor;
    const noField = !(tempFieldObj && sidebarMode === TRAINING_MODE) || !(templateTrainingField && sidebarMode === TEMPLATE_TRAINING_MODE);
    let exampleElements = [];
    const srPageSet = sidebarMode === TEMPLATE_TRAINING_MODE || sidebarMode === TRAINING_MODE ? sidebarMode === TRAINING_MODE ? pageSet : templateTrainingPageSet : null;
    const selectedPage = srPageSet ? srPageSet.pages[0] : null;
    if (templatedFieldObj && sidebarMode === TEMPLATE_TRAINING_MODE && templateTrainingPageSet.pages.length) {
      const page = templateTrainingPageSet.pages[0];
      const xpaths = page.fieldExamplesFor(templateTrainingField).map((e) => e._xpath);
      exampleElements = xpaths.map((x) => getByFullXPath(x, page.dom()));
    }
    const field = sidebarMode === TRAINING_MODE ? tempFieldObj : sidebarMode === TEMPLATE_TRAINING_MODE ? templateTrainingField : undefined;
    return (
      <>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar disableGutters={!open} className={classes.toolbar}>
              <Typography
                component="h1"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <img src={Logo} className="App-logo" alt="logo" />
              </Typography>
              <EnvironmentPicker />
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <LinearProgress value={showLoader ? undefined : 0} variant={showLoader ? 'indeterminate' : 'determinate'} />
            <SiteRenderer
              elementClickedCallback={this.elementClickedCallback}
              noField={noField}
              field={field}
              enabled={sidebarMode === TRAINING_MODE || sidebarMode === TEMPLATE_TRAINING_MODE}
              exampleElements={exampleElements}
              counterExampleElements={[]}
              pageSet={srPageSet}
              selectedPage={selectedPage}
            >
              <InteractionFrame
                onLoadingChange={(isWsLoading) => this.setState({ isWsLoading })}
                isSidebarOpen={open}
              />
            </SiteRenderer>
          </main>
          <Sidebar
            isOpen={open}
            onOpen={this.handleDrawerOpen}
            onClose={this.handleDrawerClose}
          />
        </div>
        <Snackbar
          anchorOrigin={snackbarPosition}
          open={snackbarOpen}
          onClose={this.handleSnackbarClose}
          message={snackbarMessage}
          autoHideDuration={snackbarHideDuration}
          action={snackbarAction}
        />
      </>
    );
  }
}

const componentWithStyles = withStyles(styles)(App);

const container = connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actions: dispatchProps,
}))(componentWithStyles)

export default hot(module)(container);
