import uuid from 'uuid';
import { Action } from '@import/page-interaction-sdk';
import _get from 'lodash/get';
import { actions } from '@import/replay-browser-events'
import { getActionName } from './interactionUtils';
import { WS_DEFAULT_SETTINGS } from './constants';

export class InteractionAction {
  constructor(data) {
    Object.assign(this, this.createActionMetaData(data));
  }

  createActionMetaData({
    action,
    actionId = uuid(),
    recordStatus = 'recorded',
    parentActionId,
    isAuth,
  }) {
    const actionName = getActionName(action)
    this.name = actionName;

    const interactionAction = {
      browserAction: action.toJSON ? action : actions[this.name].fromJSON(document, action),
      actionId,
      recordStatus,
      parentActionId,
      isAuth,
    };

    if (action.actions) {
      interactionAction.actions = action.actions.map((a) => createInteractionAction({ action: a, parentActionId: actionId }));
    }
    return interactionAction;
  }

  addAction(action) {
    if (!this.browserAction.actions) return;

    const { actionId, browserAction } = action;
    this.actions = this.actions || [];
    action.parentActionId = this.actionId;

    // if this is a scroll action in the context of an infinite scroll action
    // we must remove the y coordinates for the action so that will scroll to the bottom every time
    if (this.infiniteScroll && action.name === 'ScrollAction') action.y = undefined;

    const existingActionIndex = this.actions.findIndex((a) => a.actionId === actionId);
    if (existingActionIndex > -1) {
      const existingAction = this.actions[existingActionIndex];
      this.actions[existingActionIndex].mergeProps({ ...existingAction, ...action });
    } else {
      this.browserAction.actions.push(browserAction);
      this.actions.push(action);
    }
  }

  mergeProps(newProps) {
    Object.assign(this, newProps);
  }

  get name() {
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  set actions(actions) {
    this._actions = actions.map((a) => { a.parentActionId = this.actionId; return a; });
    this.browserAction.actions = actions.map((a) => a.browserAction);
  }

  get actions() {
    return this._actions;
  }

  get selector() {
    return _get(this.browserAction, 'event.target.cssSelector') || _get(this.browserAction, 'target.cssSelector');
  }

  set selector(newSelector) {
    const target = _get(this.browserAction, 'target') || _get(this.browserAction, 'event.target');
    target.cssSelector = newSelector;
  }

  get optional() {
    return _get(this.browserAction, 'optional');
  }

  set optional(optional) {
    this.browserAction.optional = optional;
  }

  get timeout() {
    return _get(this.browserAction, 'timeout');
  }

  set timeout(timeout) {
    this.browserAction.timeout = timeout;
  }

  set url(url) {
    if (this.name === 'WaitLoadingAction') {
      this._url = url;
      this.browserAction.url = url;
    } else if (this.name === 'GotoAction') {
      this._url = url;
      const variableTarget = _get(this.browserAction, 'variables.url');
      const urlType = typeof url;
      if (variableTarget) {
        const variableType = typeof variableTarget.defaultValue;
        // Warning, below is some shitty ass code, will hopefully be fixed when we pass in inputs to play function on pi-sdk
        if (variableType === 'object') {
          if (urlType === 'string') {
            variableTarget.defaultValue.url = url;
          } else if (urlType === 'object') {
            variableTarget.defaultValue = url;
          }
        } else if (variableType === 'string') {
          if (urlType === 'string') {
            variableTarget.defaultValue = url;
          } else if (urlType === 'object') {
            variableTarget.defaultValue = url.url;
          }
        }
      }
      const browserActionUrlType = typeof this.browserAction.url;
      if (browserActionUrlType === 'object') {
        if (urlType === 'object') {
          this.browserAction.url.url = url.url;
        } else if (urlType === 'string') {
          this.browserAction.url.url = url;
        }
      } else if (browserActionUrlType === 'string') {
        if (urlType === 'object') {
          this.browserAction.url = url.url;
        } else if (urlType === 'string') {
          this.browserAction.url = url;
        }
      }
    }
  }

  get url() {
    return this._url;
  }

  get trueEvent() {
    return _get(this.browserAction, 'trueEvent');
  }

  get variables() {
    return _get(this.browserAction, 'variables');
  }

  get maybeCaptcha() {
    // check for trueEvent.target here so that we don't get errors thrown in maybeCaptcha
    return _get(this.browserAction, 'maybeCaptcha') && this.trueEvent && this.trueEvent.target && this.browserAction.maybeCaptcha();
  }

  deserialize(doc) {
    try {
      const action = this.fromJSON(doc, this.browserAction);
      Object.assign(this, createInteractionAction({ action, isInitialLoad: this.isInitialLoad }));
    } catch (e) {
      // action is already deserialized
    }
  }

  toJSON() {
    const toJson = this.browserAction.toJSON();
    return toJson;
  }

  fromJSON(document, data) {
    const fromJson = Action.fromJSON(document, data);
    return fromJson;
  }

  setChildActions(browserActions) {
    this.actions = browserActions.map((a) => createInteractionAction({ action: a, parentActionId: this.actionId }));
  }

  changeVariableDefaultValue(variableType, newDefaultValue) {
    if (this.variables && this.variables[variableType]) {
      this.variables[variableType].defaultValue = newDefaultValue;
    }
  }
}

class PaginationAction extends InteractionAction {
  constructor(data) {
    super(data);
    this.infiniteScroll = data.action.infiniteScroll;
    if (this.infiniteScroll) {
      this.displayValue = 'Infinite Scroll';
    }
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      infiniteScroll: this.infiniteScroll,
    });
  }

  fromJSON(document, data) {
    const action = Action.fromJSON(document, data);
    action.infiniteScroll = !!data.infiniteScroll;
    return action;
  }
}

class ScrollAction extends InteractionAction {
  constructor(data) {
    super(data);
    this.displayValue = 'ScrollAction';
  }

  get y() {
    return _get(this.browserAction, 'y');
  }

  set y(y) {
    this.browserAction.y = y;
  }
}

class FlowControlAction extends InteractionAction {
  fromJSON(document, data) {
    const action = Action.fromJSON(document, data);
    action.name = data.name;
    return action;
  }
}

class CaptchaAction extends InteractionAction {
  get imageElement() {
    return _get(this.browserAction, 'options.imageElement') || '';
  }

  set imageElement(imageElement) {
    this.browserAction.options.imageElement = imageElement;
  }

  get inputElement() {
    return _get(this.browserAction, 'options.inputElement') || '';
  }

  set inputElement(inputElement) {
    this.browserAction.options.inputElement = inputElement;
  }
}

class ViewportAction extends InteractionAction {
  get height() {
    return _get(this.browserAction, 'height') || 0;
  }

  set height(height) {
    if (!this.initialHeight) this.initialHeight = this.height;
    this.userDefined = true;
    this.browserAction.height = height;
    // we don't support variables for height yet
    if (_get(this.browserAction, 'variables.height.defaultValue') || 0) {
      this.browserAction.variables.height.defaultValue = height;
    }
  }

  get width() {
    return _get(this.browserAction, 'width') || 0;
  }

  set width(width) {
    if (!this.initialWidth) this.initialWidth = this.width;
    this.userDefined = true;
    this.browserAction.width = width;
    // we don't support variables for width yet
    if (_get(this.browserAction, 'variables.width.defaultValue') || 0) {
      this.browserAction.variables.width.defaultValue = width;
    }
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      userDefined: this.userDefined,
      initialHeight: this.initialHeight,
      initialWidth: this.initialWidth,
    });
  }

  fromJSON(document, data) {
    const action = Action.fromJSON(document, data);
    action.userDefined = !!data.userDefined;
    action.initialHeight = data.initialHeight;
    action.initialWidth = data.initialWidth;
    return action;
  }
}

class FunctionAction extends InteractionAction {
  get args() {
    return _get(this.browserAction, 'context') || '';
  }

  set args(args) {
    this.browserAction.args = args;
  }

  get context() {
    return _get(this.browserAction, 'context') || '';
  }

  set context(context) {
    this.browserAction.context = context;
  }

  get callback() {
    return _get(this.browserAction, 'callback') || '';
  }

  set callback(callback) {
    this.browserAction.callback = callback;
  }

  get code() {
    return _get(this.browserAction, 'callback') || '';
  }

  set code(callback) {
    this.browserAction.callback = callback;
  }
}

class CodeAction extends InteractionAction {
  get code() {
    return _get(this.browserAction, 'code') || '';
  }

  set code(code) {
    this.browserAction.code = code;
  }
}

class SelectChangeAction extends InteractionAction {
  get values() {
    return (_get(this.browserAction, 'values') || []).join(',');
  }

  set values(values) {
    this.browserAction.values = values;
  }

  toSnippet() {
    return `await selectval({css: '${this.browserAction.event.target.cssSelector}'}, ${JSON.stringify(this.browserAction.event.values)})`
  }
}

class InputChangeAction extends InteractionAction {
  get value() {
    return _get(this.browserAction, 'value') || '';
  }

  set value(value) {
    this.browserAction.value = value;
  }

  get isPasswordField() {
    return this.browserAction.event && this.browserAction.event.target && this.browserAction.event.target.type === 'password';
  }

  toSnippet() {
    return `await inputval({css: '${this.browserAction.event.target.cssSelector}'}, ${JSON.stringify(this.browserAction.event.value)})`
  }
}

class ContentEditAction extends InteractionAction {
  toSnippet() {
    return `await contentEditVal({css: '${this.browserAction.event.target.cssSelector}'}, ${JSON.stringify(this.browserAction.event.content)})`
  }
}

class KeyAction extends InteractionAction {
  toSnippet() {
    return `await contentEditVal({css: '${this.browserAction.event.target.cssSelector}'}, ${JSON.stringify(this.browserAction.event.content)})`
  }
}

class ClickAction extends InteractionAction {
  toSnippet() {
    return `await click({css: '${this.browserAction.event.target.cssSelector}'})`
  }
}

class GotoAction extends InteractionAction {
  get cssEnabled() {
    return _get(this.browserAction, 'url.css_enabled') || false;
  }

  set cssEnabled(cssEnabled) {
    if (typeof _get(this.browserAction, 'url') === 'object') {
      this.browserAction.url = { ...this.browserAction.url, css_enabled: cssEnabled };
    } else {
      this.browserAction.url = { url: this.url, ...WS_DEFAULT_SETTINGS, css_enabled: cssEnabled };
    }
  }
}

export const AVAILABLE_CLASSES = [
  PaginationAction,
  FlowControlAction,
  ScrollAction,
  CaptchaAction,
  FunctionAction,
  InputChangeAction,
  SelectChangeAction,
  ViewportAction,
  GotoAction,
  CodeAction,
  ContentEditAction,
  KeyAction,
  ClickAction,
];

export function createInteractionAction(data) {
  const { action } = data;
  const actionName = getActionName(action);
  const actionClass = AVAILABLE_CLASSES.find((c) => c.constructor.displayName === actionName || c.name === actionName) || InteractionAction;
  return new actionClass(data);
}

export default createInteractionAction;
