export const SELECT_FIELD_TYPE = 'SELECT_FIELD_TYPE';
export const SET_SELECTOR = 'SET_SELECTOR';
export const SET_REGEX = 'SET_REGEX';
export const SET_REGEX_REPLACE = 'SET_REGEX_REPLACE';
export const SET_VALID_REGEX = 'SET_VALID_REGEX';
export const SET_FIELD = 'SET_FIELD_TEMPLATE';
export const SET_PAGE_SET = 'SET_PAGE_SET_TEMPLATE';
export const RESET_TRAINING = 'RESET_TRAINING_TEMPLATE';
export const SET_TEMPLATED_FIELD_OBJ = 'SET_TEMPLATED_FIELD_OBJ';
export const SET_SELECTOR_WITH_RESET_SAGA = 'SET_SELECTOR_WITH_RESET_SAGA';

export function selectFieldType(fieldType) {
  return { type: SELECT_FIELD_TYPE, fieldType };
}

export function setSelector(selector) {
  return { type: SET_SELECTOR, selector };
}

export function setRegex(regex) {
  return { type: SET_REGEX, regex };
}

export function setRegexReplace(regexReplace) {
  return { type: SET_REGEX_REPLACE, regexReplace };
}

export function setValidRegex(validRegex) {
  return { type: SET_VALID_REGEX, validRegex };
}

export function setField(field) {
  return { type: SET_FIELD, field };
}

export function setPageSet(pageSet) {
  return { type: SET_PAGE_SET, pageSet };
}

export function resetTraining() {
  return { type: RESET_TRAINING };
}

export function setTemplatedFieldObj(templatedFieldObj) {
  return { type: SET_TEMPLATED_FIELD_OBJ, templatedFieldObj };
}

export function setSelectorWithResetSaga(selector) {
  return { type: SET_SELECTOR_WITH_RESET_SAGA, selector };
}

export const TYPES = {
  SELECT_FIELD_TYPE,
  SET_SELECTOR,
  SET_REGEX,
  SET_REGEX_REPLACE,
  SET_VALID_REGEX,
  SET_FIELD,
  SET_PAGE_SET,
  SET_TEMPLATED_FIELD_OBJ,
  RESET_TRAINING,
};

export const FIELD_SETTERS = {
  setSelector,
  setRegex,
  setRegexReplace,
  setValidRegex,
  setField,
  setPageSet,
  setTemplatedFieldObj,
};
