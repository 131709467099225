import { put, takeEvery } from 'redux-saga/effects';
import {
  SET_SELECTOR_WITH_RESET_SAGA,
  resetTraining,
  setSelector,
} from '../actions/templateTraining';

function* resetAndSetSelector(action) {
  yield put(resetTraining());
  yield put(setSelector(action.selector));
}

export default [
  takeEvery(SET_SELECTOR_WITH_RESET_SAGA, resetAndSetSelector),
];
