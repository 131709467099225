import {
  UPDATE_WEBSITE_CONSOLE,
  SET_VIRTUAL_BROWSER_ID,
  SET_EXTRACTOR_MEMORY,
  SET_PRIVATE_SESSION,
  UPDATE_INTERACTION_STATE,
  PLAY_ACTION,
  REPLAY_AUTH_INTERACTIONS,
  REPLAY_INTERACTIONS,
  STOP_REPLAY,
  RECEIVED_DATA,
  SET_AUTO_REPLAY,
  SET_INTERACTION_VIEW,
  SET_ACE_CURSOR_POSITION,
  REPLAY_FULL_SEQUENCE,
  SET_COMPILING_EXTRACTOR,
  SET_COMPILING_AUTH_EXTRACTOR,
  AUTH_FINISHED,
  INTERACTIONS_FINISHED,
  SET_WEBSOCKET,
} from '../actions/interactions';

import { CLI_DISCONNECTED } from '../actions/app';

const initialState = {
  actionList: [],
  authInteractions: [],
  newActions: [],
  virtualBrowserId: '',
  privateSession: false,
  websiteConsole: [],
  memory: {},
  currentAction: null,
  isReplaying: false,
  authStatus: 'undetected', // Various states of authentication, valid values are 'undetected', 'detected', 'validation-required', 'replaying-auth', 'authenticated'
  currentInput: {},
  data: {},
  credentialsInput: {},
  isReplayingAuth: false,
  isReplayingInteractions: false,
  isReplayingFullSequence: false,
  autoReplay: true,
  selectedView: 'actionList',
  aceCursorPosition: null,
  compilingExtractor: false,
  compilingAuthExtractor: false,
  websocket: null,
};

export default function training(state = initialState, action) {
  switch (action.type) {
    case UPDATE_WEBSITE_CONSOLE:
      return {
        ...state,
        websiteConsole: action.websiteConsole,
      };
    case SET_VIRTUAL_BROWSER_ID:
      return {
        ...state,
        virtualBrowserId: action.virtualBrowserId,
      };
    case SET_EXTRACTOR_MEMORY:
      return {
        ...state,
        memory: action.memory,
      };
    case SET_PRIVATE_SESSION:
      return {
        ...state,
        privateSession: action.privateSession,
      };
    case UPDATE_INTERACTION_STATE:
      return {
        ...state,
        actionList: action.actionList || state.actionList,
        authInteractions: action.authInteractions || state.authInteractions,
        currentInput: action.currentInput || state.currentInput,
        credentialsInput: action.credentialsInput || state.credentialsInput,
        inputSchema: action.inputSchema || state.inputSchema,
        outputSchema: action.outputSchema || state.outputSchema,
        newActions: action.newActions || state.newActions,
      };
    case PLAY_ACTION:
      return {
        ...state,
        currentAction: action.currentAction,
      };
    case REPLAY_AUTH_INTERACTIONS:
      return {
        ...state,
        isReplayingAuth: true,
        websiteConsole: [],
        privateSession: true,
        authStatus: 'replaying-auth',
        virtualBrowserId: '',
        selectedView: 'authInteractions',
      };
    case REPLAY_INTERACTIONS:
      return {
        ...state,
        isReplayingInteractions: true,
        websiteConsole: [],
        selectedView: 'actionList',
      };
    case REPLAY_FULL_SEQUENCE:
      const hasAuth = !!state.authInteractions.length;
      return {
        ...state,
        isReplayingFullSequence: true,
        privateSession: hasAuth,
        virtualBrowserId: '',
        authStatus: hasAuth ? 'replaying-auth' : 'undetected',
        websiteConsole: [],
        isReplayingAuth: hasAuth,
        isReplayingInteractions: !hasAuth,
        selectedView: hasAuth ? 'authInteractions' : 'actionList',
      }
    case STOP_REPLAY:
      return {
        ...state,
        isReplayingInteractions: false,
        isReplayingAuth: false,
        isReplayingFullSequence: false,
      };
    case AUTH_FINISHED:
      return {
        ...state,
        isReplayingAuth: false,
        authStatus: action.error ? 'validation-required' : 'authenticated',
      }
    case INTERACTIONS_FINISHED:
      return {
        ...state,
        isReplayingInteractions: false,
      }
    case RECEIVED_DATA:
      return {
        ...state,
        data: action.data,
      };
    case SET_AUTO_REPLAY:
      return {
        ...state,
        autoReplay: action.autoReplay,
      }
    case SET_INTERACTION_VIEW:
      return {
        ...state,
        selectedView: action.selectedView,
      };
    case SET_ACE_CURSOR_POSITION:
      return {
        ...state,
        cursorPosition: action.cursorPosition,
      };
    case SET_COMPILING_EXTRACTOR:
      return { ...state, compilingExtractor: action.compilingExtractor };
    case SET_COMPILING_AUTH_EXTRACTOR:
      return { ...state, compilingAuthExtractor: action.compilingAuthExtractor };
    case CLI_DISCONNECTED:
      return { ...state, compilingAuthExtractor: false, compilingExtractor: false };
    case SET_WEBSOCKET:
      const { websocket } = action;
      return { ...state, websocket };
    default:
      return state;
  }
}
