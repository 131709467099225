import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { actions } from '@import/replay-browser-events'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
})

class ActionList extends Component {
  static propTypes = {
    interactions: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    isAuth: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    checkForCodeAction: PropTypes.func.isRequired,
  }

  onAddActionClick = async () => {
    // weird indentation here is necessary, don't fix this....
    const codeAction = new actions.CodeAction(
      document,
      `module.exports = async function (input) {

}`,
    );
    await this.props.actions.addAction(codeAction)
    this.props.checkForCodeAction()
  }

  render() {
    const { interactions, classes, isAuth } = this.props

    const interactionType = isAuth ? 'authentication' : 'data';
    let actionContent = (
      <div>
        <h3>
          You have no
          {interactionType}
          {' '}
          actions
        </h3>
      </div>
    )
    if (interactions.length) {
      actionContent = (
        <ul />
      )
    }
    return (
      <section className={classes.root}>
        <main className={classes.main}>
          {actionContent}
          <Button
            onClick={this.onAddActionClick}
            variant="extendedFab"
            color="secondary"
            className={classes.fab}
          >
            <AddIcon className={classes.extendedIcon} />
            Action
          </Button>
        </main>
      </section>
    )
  }
}

export default withStyles(styles)(ActionList)
