import { put, takeEvery, select } from 'redux-saga/effects';
import { Field } from '@import/web-extractor';
import {
  NEW_FIELD_SAGA,
  NEW_FIELD_DONE_SAGA,
  setTempField,
  addField,
  setTempFieldObj,
  setPageSet,
} from '../actions/training';

function* newField() {
  const state = yield select();
  const { pageSet } = state.training;
  const field = new Field({
    name: 'New Field',
    type: 'AUTO',
  });
  pageSet.fields.push(field);
  yield put(setTempField(JSON.stringify(field, null, 2)));
  yield put(setTempFieldObj(field));
  yield put(setPageSet(pageSet));
}

function* newFieldDone(action) {
  let { field } = action;
  field = new Field(field);
  yield put(setTempField(''));
  yield put(setTempFieldObj(null));
  yield put(addField(field));
}

export default [
  takeEvery(NEW_FIELD_SAGA, newField),
  takeEvery(NEW_FIELD_DONE_SAGA, newFieldDone),
];
