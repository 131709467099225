import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { hot } from 'react-hot-loader'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PlayIcon from '@material-ui/icons/PlayCircleFilled'
import PauseIcon from '@material-ui/icons/PauseCircleFilled'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Interactions from './Interactions';
import SnippetView from './SnippetView';
import * as InteractionActions from '../../actions/interactions'
import CodeEditor from './CodeEditor';
import { getIsReplaying } from '../../selectors/interactions'

function mapStateToProps(state) {
  return {
    actionList: state.interactions.actionList,
    authInteractions: state.interactions.authInteractions,
    newActions: state.interactions.newActions,
    currentInput: state.interactions.currentInput,
    credentialsInput: state.interactions.credentialsInput,
    autoReplay: state.interactions.autoReplay,
    selectedView: state.interactions.selectedView,
    compilingExtractor: state.interactions.compilingExtractor,
    compilingAuthExtractor: state.interactions.compilingAuthExtractor,
    isReplaying: getIsReplaying(state),
    cliMode: state.app.cliMode,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(InteractionActions, dispatch),
  }
}

const styles = (theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  header: {
    height: 50,
  },
  toolbar: {
    height: 50,
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  playButton: {
    borderRadius: '0',
  },
});

class InteractionSection extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    onToggleInteractions: PropTypes.func,
    areInteractionsVisible: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    isResizing: PropTypes.bool.isRequired,
    debuggingToolsOpen: PropTypes.bool.isRequired,
    // actionList: PropTypes.array.isRequired,
    // authInteractions: PropTypes.array.isRequired,
    newActions: PropTypes.array.isRequired,
    autoReplay: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    cliMode: PropTypes.bool.isRequired,
    selectedView: PropTypes.string.isRequired,
    currentInput: PropTypes.object.isRequired,
    credentialsInput: PropTypes.object.isRequired,
    toggleDisabled: PropTypes.bool.isRequired,
    isReplaying: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      editingCodeForAction: null,
      actionCode: '',
    }
  }

  handleViewChange = async (event) => {
    await this.props.actions.setInteractionView(event.target.value)
  }

  replayInteractions = async () => {
    const { selectedView } = this.props
    const { editingCodeForAction, actionCode } = this.state

    if (editingCodeForAction) await this.onCodeSave(actionCode)

    if (selectedView === 'actionList') {
      this.props.actions.replayInteractions()
    } else if (selectedView === 'authInteractions') {
      this.props.actions.replayAuth()
    }
  }

  onInputChanged(newInput, isAuth) {
    try {
      const parsedInput = JSON.parse(newInput)
      if (isAuth) this.props.actions.updateInteractionState({ credentialsInput: parsedInput })
      else this.props.actions.updateInteractionState({ currentInput: parsedInput })
    } catch (e) {
      console.error('Input is not valid json')
    }
  }

  onCodeSave = async (code) => {
    const { editingCodeForAction } = this.state;
    if (!editingCodeForAction) return;
    editingCodeForAction.code = code;
    await this.props.actions.updateAction(editingCodeForAction);
  }

  pauseInteractions = () => {
    this.props.actions.stopReplay()
  }

  render() {
    const {
      classes,
      areInteractionsVisible,
      height,
      isResizing,
      debuggingToolsOpen,
      autoReplay,
      cliMode,
      actions,
      selectedView,
      currentInput,
      credentialsInput,
      toggleDisabled,
      isReplaying,
    } = this.props
    const { editingCodeForAction } = this.state
    let subtrNbr = debuggingToolsOpen ? 95 : 105;
    if (selectedView === 'newActions') subtrNbr = debuggingToolsOpen ? 60 : 70;

    return (
      <div style={{ width: '100%' }}>
        <AppBar color="default" className={classes.header} position="relative">
          <Toolbar className={classes.toolbar}>
            <div style={{ display: 'flex' }}>
              <form>
                <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    value={selectedView}
                    onChange={this.handleViewChange}
                    input={<FilledInput name="age" id="filled-age-simple" />}
                  >
                    <MenuItem value="authInteractions">Authentication Actions</MenuItem>
                    ,
                    <MenuItem value="actionList">Data Actions</MenuItem>
                    <MenuItem value="newActions">New Actions</MenuItem>
                    <MenuItem value="currentInput">Input</MenuItem>
                    <MenuItem value="credentialsInput">Credentials</MenuItem>
                  </Select>
                </FormControl>
              </form>
              {(selectedView === 'authInteractions' || selectedView === 'actionList') && !!this.props[selectedView].length && (
                <IconButton onClick={isReplaying ? this.pauseInteractions : this.replayInteractions} className={classes.playButton}>
                  {isReplaying ? <PauseIcon /> : <PlayIcon />}
                </IconButton>
              )}
            </div>
            <div>
              {cliMode && (
                <FormControlLabel
                  control={
                    <Switch checked={autoReplay} onChange={(e, checked) => this.props.actions.setAutoReplay(checked)} color="primary" />
                  }
                  label="Auto replay"
                />
              )}
              <IconButton onClick={this.props.onToggleInteractions} disabled={toggleDisabled}>
                { areInteractionsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        { areInteractionsVisible && (
          <div style={{ height: `calc(100vh - ${window.innerHeight - (height - subtrNbr)}px)`, width: '100%' }}>
            {(() => {
              switch (selectedView) {
                case 'actionList':
                case 'authInteractions':
                  return (
                    <Interactions
                      interactions={this.props[selectedView] || []}
                      isResizing={isResizing}
                      isAuth={selectedView === 'authInteractions'}
                      debuggingToolsOpen={debuggingToolsOpen}
                      actions={actions}
                      onCodeSave={this.onCodeSave}
                      editingCodeForAction={editingCodeForAction}
                      setEditingCodeForAction={(editingCodeForAction) => this.setState({ editingCodeForAction })}
                      onCodeChange={(actionCode) => this.setState({ actionCode })}
                    />
                  )
                case 'newActions':
                  return (
                    <SnippetView
                      actions={this.props.newActions}
                      isResizing={isResizing}
                      fullSize={debuggingToolsOpen}
                    />
                  )
                case 'currentInput':
                  return (
                    <CodeEditor
                      code={JSON.stringify(currentInput || {}, null, 2)}
                      isResizing={isResizing}
                      fullSize={debuggingToolsOpen}
                      onSave={(newInput) => this.onInputChanged(newInput)}
                    />
                  )
                case 'credentialsInput':
                  return (
                    <CodeEditor
                      code={JSON.stringify(credentialsInput || {}, null, 2)}
                      isResizing={isResizing}
                      fullSize={debuggingToolsOpen}
                      onSave={(newInput) => this.onInputChanged(newInput, true)}
                    />
                  )
                default:
                  return null
              }
            })()}
          </div>
        )}
      </div>
    );
  }
}

const componentWithStyles = withStyles(styles)(InteractionSection);

const container = connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  actions: dispatchProps,
}))(componentWithStyles)

export default hot(module)(container);
