import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import CodeEditor from './CodeEditor'
import ActionList from './ActionList'

export default class Interactions extends Component {
  static propTypes = {
    interactions: PropTypes.array.isRequired,
    isResizing: PropTypes.bool.isRequired,
    debuggingToolsOpen: PropTypes.bool.isRequired,
    isAuth: PropTypes.bool.isRequired,
    actions: PropTypes.object,
    setEditingCodeForAction: PropTypes.func.isRequired,
    onCodeSave: PropTypes.func.isRequired,
    onCodeChange: PropTypes.func.isRequired,
    editingCodeForAction: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      codeActionExtractor: false,
    }
  }

  async componentDidMount() {
    if (this.props.interactions.length === 1
        && this.props.interactions[0].name === 'CodeAction') {
      await this.setState({ codeActionExtractor: true })
      this.props.setEditingCodeForAction(this.props.interactions[0])
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.interactions, this.props.interactions)) {
      this.checkForCodeAction()
    }
  }

  checkForCodeAction = async () => {
    if (this.props.interactions.length === 1 && this.props.interactions[0].name === 'CodeAction') {
      await this.setState({ codeActionExtractor: true })
      this.props.setEditingCodeForAction(this.props.interactions[0])
    } else {
      await this.setState({ codeActionExtractor: false })
      this.props.setEditingCodeForAction(null)
    }
  }

  render() {
    const {
      isResizing, debuggingToolsOpen, interactions, isAuth, editingCodeForAction,
    } = this.props
    if (editingCodeForAction) {
      return (
        <CodeEditor
          code={editingCodeForAction.code}
          isResizing={isResizing}
          fullSize={debuggingToolsOpen}
          onCodeChange={this.props.onCodeChange}
          onSave={this.props.onCodeSave}
        />
      )
    }
    return (
      <ActionList
        interactions={interactions}
        isAuth={isAuth}
        checkForCodeAction={this.checkForCodeAction}
        actions={this.props.actions}
      />
    )
  }
}
