import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
import appSaga from '../sagas';

// hook up saga
const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  thunk,
  sagaMiddleware,
];

let store;

if (process.env.NODE_ENV !== 'production') {
  const hiddenActionTypes = [
    // none for now
  ];
  const logger = createLogger({
    predicate: (getState, action) => !hiddenActionTypes.includes(action.type),
    collapsed: true,
  });
  middlewares.push(logger);
  store = {
    ...createStore(
      reducers,
      composeWithDevTools(
        applyMiddleware(...middlewares),
      ),
    ),
    runSaga: sagaMiddleware.run,
  };
} else {
  const createStoreWithMiddleware = applyMiddleware(
    ...middlewares,
  )(createStore);
  store = { ...createStoreWithMiddleware(reducers), runSaga: sagaMiddleware.run };
}
store.runSaga(appSaga);

export default store;
