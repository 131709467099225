import jssdk, { createSdk } from '../utils/jssdk'

export const LOADING_USER = 'LOADING_USER';
export const USER_RECEIVED = 'USER_RECEIVED';
export const SET_API_KEY = 'SET_API_KEY';

export function getCurrentUser() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_USER })
      const currentUser = await jssdk.auth.currentUser()
      dispatch(({ type: USER_RECEIVED, currentUser }))
      if (!getState().user.apiKey) dispatch(getApiKey())
    } catch (e) {
      console.error('Error fetching user', e)
      dispatch(({ type: USER_RECEIVED, currentUser: null }))
    }
  }
}

export function getApiKey() {
  return async (dispatch) => {
    try {
      const response = await jssdk.auth.getApiKey()
      dispatch(setApiKey(response.apiKey))
    } catch (e) {
      console.error('Unable to get apiKey', e)
    }
  }
}

export function setApiKey(apiKey) {
  createSdk({ apiKey })
  return { type: SET_API_KEY, apiKey }
}
